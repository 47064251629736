import * as React from 'react';

function SvgIconCopy(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeWidth="1.25"
        d="M14.5 3v4.636H19M7 6.091H4V20h12v-3.09m3 0H7V3h8.25L19 6.864v10.045z"
      />
    </svg>
  );
}

export default SvgIconCopy;
