import * as React from 'react';

function IconForms(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="28" cy="28" r="28" fill="#E7DEFC" />
      <rect x="16.5" y="32" width="23" height="10" stroke="#7119E1" strokeWidth="1.25" strokeLinejoin="round" />
      <rect x="16.5" y="25" width="23" height="3" stroke="#7119E1" strokeWidth="1.25" strokeLinejoin="round" />
      <rect x="16.5" y="16" width="23" height="3" stroke="#7119E1" strokeWidth="1.25" strokeLinejoin="round" />
      <line x1="17.125" y1="22.375" x2="25.875" y2="22.375" stroke="#7119E1" strokeWidth="1.25" strokeLinecap="round" />
      <line x1="17.125" y1="13.375" x2="25.875" y2="13.375" stroke="#7119E1" strokeWidth="1.25" strokeLinecap="round" />
    </svg>
  );
}

export default IconForms;
