import * as React from 'react';

function SvgIconAccessibility(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19.65C16.225 19.65 19.65 16.225 19.65 12C19.65 7.77502 16.225 4.35 12 4.35C7.77502 4.35 4.35 7.77502 4.35 12C4.35 16.225 7.77502 19.65 12 19.65ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
        fill="currentColor"
      />
      <path
        d="M12.1587 14.0904C12.1247 14.1729 12.1016 14.2227 12.0834 14.2737C11.5832 15.6007 11.0842 16.9278 10.5827 18.2536C10.448 18.6094 10.1517 18.8048 9.77656 18.7951C9.43296 18.7866 9.15006 18.579 9.03958 18.2573C8.97159 18.0594 8.9983 17.8675 9.06144 17.6721C9.37469 16.7141 9.70979 15.7634 9.98661 14.7945C10.3654 13.4736 10.522 12.1162 10.5245 10.7418C10.5245 10.454 10.4577 10.3715 10.176 10.3071C9.10393 10.0655 8.03185 9.82388 6.95976 9.58348C6.70722 9.52763 6.50325 9.40621 6.38426 9.16946C6.14508 8.69352 6.51175 8.13016 7.06297 8.1593C7.42478 8.17872 7.78416 8.26007 8.14476 8.31106C9.26662 8.47133 10.3776 8.69959 11.5152 8.74937C12.5557 8.7955 13.5865 8.71901 14.6137 8.55632C15.4454 8.42398 16.2783 8.29285 17.1112 8.16415C17.5495 8.09616 17.9307 8.34749 17.9987 8.74451C18.0667 9.13668 17.8081 9.48028 17.3686 9.57984C16.2965 9.82266 15.2244 10.0631 14.1523 10.3047C13.8585 10.3702 13.7966 10.4479 13.7954 10.7491C13.7832 12.7924 14.2203 14.7508 14.9039 16.6631C15.0265 17.0067 15.1455 17.3503 15.2645 17.6951C15.4235 18.1541 15.2341 18.5863 14.8067 18.7453C14.3672 18.908 13.9046 18.6968 13.7359 18.25C13.2344 16.9241 12.7354 15.5971 12.2352 14.27C12.2145 14.2203 12.1927 14.1717 12.1587 14.0904Z"
        fill="currentColor"
      />
      <path
        d="M12.1564 5.58155C12.9395 5.57912 13.5697 6.20197 13.5758 6.98631C13.583 7.77307 12.9468 8.41656 12.1613 8.41777C11.377 8.41899 10.7347 7.77549 10.7395 6.99116C10.7444 6.20926 11.3721 5.58398 12.1564 5.58155Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconAccessibility;
