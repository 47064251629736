import { Box as GrBox, BoxTypes } from 'grommet';
import React from 'react';
import styled, { CSSObject } from 'styled-components';

type BoxType = Omit<BoxTypes, 'round' | 'border'>;

export type ContentBoxVariant =
  | 'white'
  | 'default'
  | 'white-accent'
  | 'gray'
  | 'gray-accent'
  | 'dark-gray'
  | 'brand'
  | 'info'
  | 'alert-green'
  | 'success'
  | 'alert-yellow'
  | 'warning'
  | 'alert-red'
  | 'error'
  | 'base';
export type ContentBoxCorner =
  | 'top'
  | 'left'
  | 'bottom'
  | 'right'
  | 'top-left'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-right';

export interface ContentBoxProps extends BoxType {
  /**
   * Predefined color variants
   */
  variant?: ContentBoxVariant;
  /**
   * Specify which corners to apply border radius rounding to.
   */
  corner?: ContentBoxCorner;
}

const StyledContentBox = styled(GrBox)<ContentBoxProps>(({ variant = 'default', theme }) => {
  const css: CSSObject = {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.black,
    backgroundColor: theme.palette.white,
  };

  switch (variant) {
    case 'base': {
      css.borderColor = theme.global?.colors?.primaryBorder as string;
      css.backgroundColor = theme.global?.colors?.primaryBg as string;
      break;
    }
    case 'white-accent': {
      css.borderColor = theme.palette['gray-400'];
      css.backgroundColor = theme.palette.white;
      css.color = theme.palette.black;
      break;
    }
    case 'gray': {
      css.borderColor = theme.palette['gray-200'];
      css.backgroundColor = theme.palette['gray-50'];
      css.color = theme.palette.black;
      break;
    }
    case 'gray-accent': {
      css.borderColor = theme.palette['gray-400'];
      css.backgroundColor = theme.palette['gray-50'];
      css.color = theme.palette.black;
      break;
    }
    case 'dark-gray': {
      css.borderColor = theme.palette['gray-600'];
      css.backgroundColor = theme.palette['gray-400'];
      css.color = theme.palette.white;
      break;
    }
    case 'brand':
    case 'info': {
      css.borderColor = theme.palette['purple-200'];
      css.backgroundColor = theme.palette['purple-100'];
      css.color = theme.palette['purple-900'];
      break;
    }
    case 'alert-green':
    case 'success': {
      css.borderColor = theme.palette['green-600'];
      css.backgroundColor = theme.palette['green-200'];
      css.color = theme.palette['green-800'];
      break;
    }
    case 'alert-red':
    case 'error': {
      css.borderColor = theme.palette['red-400'];
      css.backgroundColor = theme.palette['red-50'];
      css.color = theme.palette['red-800'];
      break;
    }
    case 'alert-yellow':
    case 'warning': {
      css.borderColor = theme.palette['yellow-400'];
      css.backgroundColor = theme.palette['yellow-50'];
      css.color = theme.palette['yellow-800'];
      break;
    }
    case 'white':
    case 'default':
    default: {
      css.borderColor = theme.palette['gray-200'];
      css.backgroundColor = theme.palette.white;
      css.color = theme.palette.black;
      break;
    }
  }

  return css;
});

/**
 * Basic styled box for displaying content with predefined styled variants.
 * Extends all (most) props of [grommet box](https://v2.grommet.io/box#props).
 */
export const ContentBox: React.FC<ContentBoxProps> = ({
  variant = 'default',
  pad = 'small',
  corner,
  ...rest
}: ContentBoxProps) => <StyledContentBox {...rest} variant={variant} pad={pad} round={{ corner, size: '16px' }} />;
