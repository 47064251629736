import { ThemeType as GrommetThemeType } from 'grommet';

import { breakpoints } from './breakpoints';
import { CustomThemeType } from './CustomThemeType';
import { device } from './device';
import { grommetTheme } from './grommet-theme';
import { paddingPalette, palette, radiusPalette } from './palette';
import { zIndex } from './zIndex';

export const customTheme: CustomThemeType = {
  breakpoints,
  device,
  palette,
  radiusPalette,
  paddingPalette,
  zIndex,
  height: {
    header: 60,
    footerMobile: 96,
  },
  padding: {
    global: 8,
  },
  spacing: {
    micro: 12,
    xxsmall: 16,
    small: 24,
    medium: 32,
    large: 48,
    xlarge: 56,
    xxlarge: 64,
    xxxlarge: 88,
  },
  width: {
    maxWidth: 1280,
  },
  modal: {
    borderRadius: '32px',
    boxShadow: '0px 0px 32px rgba(44, 4, 97, 0.16)',
    padding: '2.5rem',
    color: palette.black,
    backgroundColor: palette.white,
    justifyContent: 'space-between',
    overflowY: 'auto',
  },
  modalHeader: {
    borderBottom: `1px solid ${palette['gray-100']}`,
  },
  modalFooter: {
    borderTop: `1px solid ${palette['gray-100']}`,
    flexDirection: 'row',
    gap: '0.75rem',
    paddingTop: '2.5rem',
  },
  unorderedList: {
    paddingLeft: '1em',
    li: {
      listStylePosition: 'outside',
    },
  },
  orderedList: {
    listStyle: 'none',
    counterReset: 'item-number',
    padding: 0,
  },
  orderedListItems: {
    counterIncrement: 'item-number',
    display: 'flex',
    alignItems: 'center',
  },
  orderedListNumbers: {
    content: 'counter(item-number) ". "',
    fontWeight: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    paddingRight: '5px',
    alignSelf: 'start',
    width: '20px', // necessary in case font's numbers have different widths
  },
  horizontalRule: {
    width: '100%',
    height: '1px',
    border: 'none',
    margin: '32px 0px',
    backgroundColor: palette['gray-100'],
  },
  expertServices: {
    gradient: {
      left: '#9005D1',
      right: '#FF338A',
    },
  },
  filterPills: {},
  filterTogglePill: {},
};

export type ThemeType = CustomThemeType & GrommetThemeType;

export const theme: ThemeType = {
  ...customTheme,
  ...grommetTheme,
};
