import { Text as GrText, TextProps as GrTextProps } from 'grommet';
import { MarginType, PolymorphicType, TextAlignType } from 'grommet/utils';
import React, { PropsWithChildren, ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

import { palette } from '../Theme/palette';

export type TextVariant =
  | 'xxxx-lg-heavy'
  | 'xxx-lg-heavy'
  | 'xx-lg-heavy'
  | 'xx-lg-light'
  | 'x-lg-heavy'
  | 'x-lg-light'
  | 'lg-heavy'
  | 'lg-light'
  | 'med'
  | 'med-light'
  | 'reg-heavy'
  | 'reg'
  | 'small'
  | 'small-heavy'
  | 'x-small'
  | 'x-small-heavy'
  | 'x-small-caps'
  | 'captions-sm'
  | 'captions-lg'
  | 'screen-reader-only'
  | 'code';

export const textVariantStyle: Record<TextVariant, CSSObject> = {
  'xxxx-lg-heavy': {
    fontWeight: 700,
    fontSize: '4rem',
    lineHeight: 1.5,
  },
  'xxx-lg-heavy': {
    fontWeight: 700,
    fontSize: '3rem',
    lineHeight: 1.5,
  },
  'xx-lg-heavy': {
    fontWeight: 700,
    fontSize: '2.25rem',
    lineHeight: 1.5,
  },
  'xx-lg-light': {
    fontWeight: 200,
    fontSize: '2.25rem',
    lineHeight: 1.5,
  },
  'x-lg-heavy': {
    fontWeight: 700,
    fontSize: '1.75rem',
    lineHeight: 1.5,
  },
  'x-lg-light': {
    fontWeight: 200,
    fontSize: '1.75rem',
    lineHeight: 1.5,
  },
  'lg-heavy': {
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: 1.5,
  },
  'lg-light': {
    fontWeight: 200,
    fontSize: '1.5rem',
    lineHeight: 1.5,
  },
  med: {
    fontWeight: 600,
    fontSize: '1.125rem',
    lineHeight: 1.5,
  },
  'med-light': {
    fontWeight: 200,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  'reg-heavy': {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  reg: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  small: {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.5,
  },
  'small-heavy': {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: 1.5,
  },
  'x-small': {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.5,
  },
  'x-small-heavy': {
    fontWeight: 600,
    fontSize: '0.75rem',
    lineHeight: 1.5,
  },
  'x-small-caps': {
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.5,
    textTransform: 'uppercase',
    letterSpacing: '5%',
  },
  'captions-lg': {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  'captions-sm': {
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.5,
  },
  'screen-reader-only': {
    position: 'absolute',
    width: '1px',
    clip: 'rect(0 0 0 0)',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  code: {
    fontFamily: 'Menlo Regular',
    fontWeight: 400,
    fontSize: '0.75rem',
    lineHeight: 1.5,
    letterSpacing: '3%',
  },
};

type ThemeColor = keyof typeof palette;

const addColor = (key: keyof typeof textVariantStyle) =>
  styled(GrText)<TextProps>(({ theme, color }) => ({
    ...textVariantStyle[key],
    color: theme.palette[color as ThemeColor],
  }));

const VariantComponents = {
  'xxxx-lg-heavy': addColor('xxxx-lg-heavy'),
  'xxx-lg-heavy': addColor('xxx-lg-heavy'),
  'xx-lg-heavy': addColor('xx-lg-heavy'),
  'xx-lg-light': addColor('xx-lg-light'),
  'x-lg-heavy': addColor('x-lg-heavy'),
  'x-lg-light': addColor('x-lg-light'),
  'lg-heavy': addColor('lg-heavy'),
  'lg-light': addColor('lg-light'),
  med: addColor('med'),
  'med-light': addColor('med-light'),
  'reg-heavy': addColor('reg-heavy'),
  reg: addColor('reg'),
  small: addColor('small'),
  'small-heavy': addColor('small-heavy'),
  'x-small': addColor('x-small'),
  'x-small-heavy': addColor('x-small-heavy'),
  'x-small-caps': addColor('x-small-caps'),
  'captions-sm': addColor('captions-sm'),
  'captions-lg': addColor('captions-lg'),
  'screen-reader-only': addColor('screen-reader-only'),
  code: addColor('code'),
};

export interface TextProps extends Omit<GrTextProps, 'color' | 'alignSelf' | 'gridArea' | 'size' | 'tag' | 'weight'> {
  /**
   * Custom label to be used by screen readers.
   * When provided, an aria-label will be added to the element.
   */
  a11yTitle?: string;
  /**
   * The DOM tag or react component to use for the element
   */
  as?: PolymorphicType;
  /**
   * Text content.
   */
  children?: ReactNode;
  /**
   * The amount of margin around the component.
   * An object can be specified to distinguish horizontal margin,
   * vertical margin, and margin on a particular side.
   */
  margin?: MarginType;
  /**
   * How to align the text inside the component.
   */
  textAlign?: TextAlignType;
  /**
   * Restrict the text to a single line and truncate with ellipsis if it is too long to all fit.
   * For truncate to be applied, Text needs to be contained within a layout component.
   */
  truncate?: boolean;
  /**
   * Pre-styled text variant.
   */
  variant?: TextVariant;

  /**
   * One of the colors from the inclusive-ui theme
   */
  color?: ThemeColor;

  /**
   * Whether words should break when reaching the end of a line.
   */
  wordBreak?: 'normal' | 'break-all' | 'keep-all' | 'break-word';
  id?: string;
  role?: string;
  className?: string;
}

/**
 * General use text component with predefined variant styles.
 */
export const Text: React.FC<PropsWithChildren<TextProps>> = ({
  variant = 'med',
  as = 'span',
  wordBreak = 'normal',
  ...rest
}) => {
  const TextVariantComponent = VariantComponents[variant];

  return <TextVariantComponent {...rest} forwardedAs={as} wordBreak={wordBreak} />;
};
