import React from 'react';

function SvgIconCircle({ color = 'currentColor', ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <circle cx="12.5" cy="12" r="11" stroke={color} strokeWidth="2" />
    </svg>
  );
}

export default SvgIconCircle;
