import * as React from 'react';

function SvgIconContrast(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="28" cy="28" r="28" fill="#E7DEFC" />
      <path
        d="M28 41C31.4478 41 34.7544 39.6304 37.1924 37.1924C39.6304 34.7544 41 31.4478 41 28C41 24.5522 39.6304 21.2456 37.1924 18.8076C34.7544 16.3696 31.4478 15 28 15L28 28L28 41Z"
        fill="#7119E1"
        stroke="#7119E1"
        strokeWidth="1.25"
      />
      <path
        d="M28 41C24.5522 41 21.2456 39.6304 18.8076 37.1924C16.3696 34.7544 15 31.4478 15 28C15 24.5522 16.3696 21.2456 18.8076 18.8076C21.2456 16.3696 24.5522 15 28 15L28 28L28 41Z"
        stroke="#7119E1"
        strokeWidth="1.25"
      />
    </svg>
  );
}

export default SvgIconContrast;
