import React from 'react';

function SvgIconCircleCheck({ color = 'currentColor', ...rest }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path
        d="M24.5 12C24.5 15.1826 23.2357 18.2348 20.9853 20.4853C18.7348 22.7357 15.6826 24 12.5 24C9.3174 24 6.26516 22.7357 4.01472 20.4853C1.76428 18.2348 0.5 15.1826 0.5 12C0.5 8.8174 1.76428 5.76516 4.01472 3.51472C6.26516 1.26428 9.3174 0 12.5 0C15.6826 0 18.7348 1.26428 20.9853 3.51472C23.2357 5.76516 24.5 8.8174 24.5 12ZM18.545 7.455C18.4379 7.34823 18.3103 7.26416 18.1699 7.20782C18.0295 7.15149 17.8792 7.12404 17.728 7.12712C17.5768 7.1302 17.4277 7.16375 17.2897 7.22575C17.1518 7.28776 17.0277 7.37695 16.925 7.488L11.7155 14.1255L8.576 10.9845C8.36274 10.7858 8.08067 10.6776 7.78922 10.6827C7.49776 10.6879 7.21969 10.8059 7.01357 11.0121C6.80745 11.2182 6.68938 11.4963 6.68424 11.7877C6.6791 12.0792 6.78728 12.3612 6.986 12.5745L10.955 16.545C11.0619 16.6517 11.1892 16.7358 11.3294 16.7923C11.4695 16.8487 11.6196 16.8764 11.7706 16.8736C11.9217 16.8708 12.0706 16.8376 12.2085 16.776C12.3465 16.7144 12.4706 16.6256 12.5735 16.515L18.5615 9.03C18.7656 8.81775 18.8784 8.53391 18.8756 8.23944C18.8728 7.94496 18.7546 7.66332 18.5465 7.455H18.545Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgIconCircleCheck;
