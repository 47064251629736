import { Button as GrButton, ButtonType as GrButtonProps } from 'grommet';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

export type ButtonType = GrButtonProps;
export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'text' | 'black' | 'danger' | 'plain';

const StyledButton = styled(GrButton)<ButtonProps>(({ variant = 'primary', theme }) => ({
  // Base Style
  borderRadius: 8,
  borderWidth: 2,
  fontSize: '1rem',
  lineHeight: 1,
  fontWeight: 500,
  padding: '0.8125rem 1rem',
  position: 'relative',

  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.deactivated, &:disabled': {
    opacity: 0.15,
  },

  // Add Variant style
  ...(variant === 'primary'
    ? {
        backgroundColor: theme.global?.colors?.brand as string,
        border: `1.25px solid ${theme.global?.colors?.brand as string}`,
        color: theme.global?.colors?.brandText as string,

        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: theme.global?.colors?.brandHover as string,
          border: `1.25px solid ${theme.global?.colors?.brandHover as string}`,
          color: theme.global?.colors?.brandHoverText as string,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:active': {
          backgroundColor: theme.global?.colors?.brandActive as string,
          border: `1.25px solid ${theme.global?.colors?.brandActive as string}`,
          color: theme.global?.colors?.brandActiveText as string,
        },
      }
    : {}),
  ...(variant === 'secondary'
    ? {
        backgroundColor: theme.global?.colors?.secondary as string,
        border: `1.25px solid ${theme.global?.colors?.secondaryHover as string}`,
        color: theme.global?.colors?.secondaryHoverText as string,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: theme.global?.colors?.secondaryHover as string,
          border: `1.25px solid ${theme.global?.colors?.secondaryHover as string}`,
          color: theme.global?.colors?.secondaryHoverText as string,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:active': {
          backgroundColor: theme.global?.colors?.secondaryActive as string,
          border: `1.25px solid ${theme.global?.colors?.secondaryActive as string}`,
          color: theme.global?.colors?.secondaryActiveText as string,
        },
      }
    : {}),
  ...(variant === 'text'
    ? {
        backgroundColor: 'transparent',
        border: '1.25px solid transparent',
        color: theme.global?.colors?.brand as string,

        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: theme.palette['gray-100'],
          border: `1.25px solid ${theme.palette['gray-100']}`,
          color: theme.global?.colors?.brand as string,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:active': {
          backgroundColor: theme.palette['gray-200'],
          border: `1.25px solid ${theme.palette['gray-200']}`,
          color: theme.global?.colors?.brandActive as string,
        },
      }
    : {}),
  ...(variant === 'black'
    ? {
        backgroundColor: theme.palette.black,
        border: `1.25px solid ${theme.palette.black}`,
        color: theme.palette.white,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:after.focus': {
          border: `3px solid ${theme.palette.black}`,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: theme.palette['gray-800'],
          border: `1.25px solid ${theme.palette['gray-800']}`,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:active': {
          backgroundColor: theme.palette['gray-600'],
          border: `1.25px solid ${theme.palette['gray-600']}`,
        },
      }
    : {}),
  ...(variant === 'danger'
    ? {
        backgroundColor: theme.palette['red-600'],
        border: `1.25px solid ${theme.palette['red-600']}`,
        color: theme.palette.white,

        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: theme.palette['red-400'],
          border: `1.25px solid ${theme.palette['red-400']}`,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:active': {
          backgroundColor: theme.palette['red-200'],
          border: `1.25px solid ${theme.palette['red-200']}`,
        },
      }
    : {}),
  ...(variant === 'plain'
    ? {
        backgroundColor: 'transparent',
        borderRadius: 0,
        borderWidth: 0,
        padding: 0,
        color: theme.palette['purple-600'],
        fontSize: 'inherit',
        fontWeight: '600',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:hover': {
          textDecoration: 'underline',
        },
      }
    : {}),
  ...(variant === 'tertiary'
    ? {
        backgroundColor: 'transparent',
        border: '1.25px solid transparent',
        color: theme.global?.colors?.secondaryText as string,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:hover': {
          boxShadow: 'none',
          border: `1.25px solid ${theme.global?.colors?.secondaryHover as string}`,
          color: theme.global?.colors?.secondaryHoverText as string,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:active': {
          border: `1.25px solid ${theme.global?.colors?.secondaryActive as string}`,
          color: theme.global?.colors?.secondaryActiveText as string,
        },
      }
    : {}),
}));

export interface ButtonProps extends Omit<GrButtonProps, 'ref'> {
  /**
   * class attribute
   */
  className?: string;
  /**
   * Apply deactivated style to button.
   * NOTE: Button is not `disabled` when `deactivated`. It only gets `deactivated` styles applied.
   */
  deactivated?: boolean;
  /**
   *  Button label
   */
  label?: ReactNode;
  /**
   * Button Click handler
   */
  onClick?: GrButtonProps['onClick'];
  /**
   * Icon element to render next to label. Icon position controlled by `reverse` prop.
   */
  icon?: JSX.Element;
  /**
   * Reverese icon position. Default icon position is leading-icon, `reverse` is trailing-icon.
   */
  reverse?: boolean;
  /**
   * Button style variant
   */
  variant?: ButtonVariant;
  /**
   * Optional props used when button is rendered as react-csv CSVLink
   */
  data?: string | Record<string, unknown>[];
  headers?: { label: string; key: string }[] | undefined;
  filename?: string | undefined;
}

/**
 * Basic button element with styled variants
 */
export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ label, variant, deactivated, className = '', plain, ...rest }: ButtonProps, ref) => (
    <StyledButton
      gap="xsmall"
      ref={ref}
      className={`${className}${deactivated ? ' deactivated' : ''}`}
      label={label}
      variant={plain && !variant ? undefined : variant || 'primary'}
      plain={plain}
      {...rest}
    />
  ),
);
