import { Image as GrImage, ImageProps as GrImageProps } from 'grommet';
import React, { useMemo } from 'react';

import {
  adminLocalLogoDataUri,
  adminProductionLogoDataUri,
  adminReviewLogoDataUri,
  adminStagingLogoDataUri,
  logoDataUri,
  poweredByDataUri,
  purpleLogoWithTextDataUri,
} from './data-uri';

export type LogoVariant =
  | 'text_with_purple_logo'
  | 'poweredby'
  | 'logo'
  | 'admin_logo_production'
  | 'admin_logo_staging'
  | 'admin_logo_local'
  | 'admin_logo_review';

export interface AudioEyeLogoProps extends GrImageProps {
  /**
   * Logo variant for display
   */
  variant?: LogoVariant;
}

/**
 * Audioeye logo image with scalable svg data-uri src. Extends all [grommet `Image` props](https://v2.grommet.io/image#props).
 */
export const AudioEyeLogo: React.FC<AudioEyeLogoProps & JSX.IntrinsicElements['img']> = ({
  variant = 'text_with_purple_logo',
  a11yTitle = 'Audioeye Logo',
  alt = 'audioeye',
  ...rest
}: AudioEyeLogoProps & JSX.IntrinsicElements['img']) => {
  const dataUri = useMemo(() => {
    switch (variant) {
      case 'text_with_purple_logo': {
        return purpleLogoWithTextDataUri;
      }
      case 'poweredby': {
        return poweredByDataUri;
      }
      case 'admin_logo_production': {
        return adminProductionLogoDataUri;
      }
      case 'admin_logo_staging': {
        return adminStagingLogoDataUri;
      }
      case 'admin_logo_review': {
        return adminReviewLogoDataUri;
      }
      case 'admin_logo_local': {
        return adminLocalLogoDataUri;
      }
      case 'logo':
      default: {
        return logoDataUri;
      }
    }
  }, [variant]);
  return <GrImage {...rest} a11yTitle={a11yTitle} alt={alt} src={dataUri} />;
};
