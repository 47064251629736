import * as React from 'react';

function IconLinks(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="28" cy="28" r="28" fill="#E7DEFC" />
      <path
        d="M25.9297 29.6669C26.4665 30.3846 27.1514 30.9784 27.9379 31.4081C28.7244 31.8378 29.5941 32.0933 30.488 32.1574C31.3819 32.2214 32.2792 32.0924 33.1189 31.7792C33.9586 31.4659 34.7211 30.9758 35.3547 30.3419L39.1047 26.5919C40.2432 25.4132 40.8731 23.8344 40.8589 22.1957C40.8447 20.557 40.1874 18.9894 39.0286 17.8306C37.8698 16.6718 36.3022 16.0145 34.6634 16.0002C33.0247 15.986 31.446 16.616 30.2672 17.7544L28.1172 19.8919"
        stroke="#7119E1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.9294 27.1665C30.3926 26.4489 29.7078 25.8551 28.9213 25.4254C28.1348 24.9957 27.2651 24.7401 26.3711 24.6761C25.4772 24.6121 24.58 24.7411 23.7403 25.0543C22.9006 25.3675 22.1381 25.8577 21.5044 26.4915L17.7544 30.2415C16.616 31.4203 15.986 32.9991 16.0002 34.6378C16.0145 36.2765 16.6718 37.8441 17.8306 39.0029C18.9894 40.1617 20.557 40.819 22.1957 40.8333C23.8344 40.8475 25.4132 40.2175 26.5919 39.079L28.7294 36.9415"
        stroke="#7119E1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconLinks;
