import { CSSObject } from 'styled-components';

export const baseNodeStyle: CSSObject = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  fontSize: '0.875rem',
  height: 44,
  justifyContent: 'center',
  width: 44,
};
