import React, { MouseEvent, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { baseNodeStyle } from './style';
import { PageNodeProps } from './types';

const StyledNavigationButton = styled(Button)(baseNodeStyle);

export const PreviousPageNavigation: React.FC<PageNodeProps> = ({
  current,
  page,
  total,
  params,
  onClick = () => undefined,
  btnProps,
}) => {
  const nodeProps = useMemo(() => ({ page, current, total, params }), [page, current, total, params]);
  const clickHandler = useCallback(() => {
    onClick(nodeProps);
  }, [nodeProps, onClick]);
  const moreBtnProps = useMemo(() => {
    if (typeof btnProps === 'function') {
      return btnProps(nodeProps);
    }
    return btnProps;
  }, [btnProps, nodeProps]);
  const disabled = current === 1;

  return (
    <StyledNavigationButton
      data-cy="previousPage"
      a11yTitle={`Previous Page (${page})`}
      icon={<Icon type="previous" />}
      onClick={clickHandler}
      disabled={disabled}
      variant="text"
      {...(!disabled ? moreBtnProps : {})}
    />
  );
};

export const NextPageNavigation: React.FC<PageNodeProps> = ({
  current,
  page,
  total,
  params,
  onClick = () => undefined,
  btnProps,
}) => {
  const nodeProps = useMemo(() => ({ page, current, total, params }), [page, current, total, params]);
  const clickHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      event.preventDefault();
      onClick(nodeProps);
    },
    [nodeProps, onClick],
  );
  const moreBtnProps = useMemo(() => {
    if (typeof btnProps === 'function') {
      return btnProps(nodeProps);
    }
    return btnProps;
  }, [btnProps, nodeProps]);
  const disabled = current === total;

  return (
    <StyledNavigationButton
      data-cy="nextPage"
      a11yTitle={`Next Page (${page})`}
      icon={<Icon type="next" />}
      onClick={clickHandler}
      disabled={disabled}
      variant="text"
      {...(!disabled ? moreBtnProps : {})}
    />
  );
};
