import * as React from 'react';

function SvgIconMail(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path strokeWidth="1.5" d="M1 5l11 8.5L23 5M1 4h22v16H1V4z" />
    </svg>
  );
}

export default SvgIconMail;
