import * as React from 'react';

function IconArrowRight(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.49997C0 5.30106 0.0790178 5.11029 0.21967 4.96964C0.360322 4.82899 0.551088 4.74997 0.75 4.74997H9.4395L6.219 1.53097C6.07817 1.39014 5.99905 1.19913 5.99905 0.999971C5.99905 0.800808 6.07817 0.609801 6.219 0.468971C6.35983 0.328141 6.55084 0.249023 6.75 0.249023C6.94916 0.249023 7.14017 0.328141 7.281 0.468971L11.781 4.96897C11.8508 5.03864 11.9063 5.1214 11.9441 5.21252C11.9819 5.30364 12.0013 5.40132 12.0013 5.49997C12.0013 5.59862 11.9819 5.6963 11.9441 5.78742C11.9063 5.87854 11.8508 5.9613 11.781 6.03097L7.281 10.531C7.14017 10.6718 6.94916 10.7509 6.75 10.7509C6.55084 10.7509 6.35983 10.6718 6.219 10.531C6.07817 10.3901 5.99905 10.1991 5.99905 9.99997C5.99905 9.80081 6.07817 9.6098 6.219 9.46897L9.4395 6.24997H0.75C0.551088 6.24997 0.360322 6.17095 0.21967 6.0303C0.0790178 5.88965 0 5.69888 0 5.49997Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconArrowRight;
