import { breakpoints } from './breakpoints';

export interface ThemeDevice {
  small: string;
  medium: string;
  largest: string;
}

export const device: ThemeDevice = {
  small: `@media screen and (max-width: ${breakpoints.small}px)`,
  medium: `@media screen and (max-width: ${breakpoints.medium}px)`,
  largest: `@media screen and (min-width: ${breakpoints.medium + 1}px)`,
};
