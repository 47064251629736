import { BoxProps, Card as GrommetCard, Heading, Paragraph } from 'grommet';
import React from 'react';
import styled from 'styled-components';

import { palette } from '../Theme/palette';

export interface CardProps extends Omit<BoxProps, 'title'> {
  title?: React.ReactNode;
  level?: '1' | '2' | '3' | '4' | '5' | '6' | 1 | 2 | 3 | 4 | 5 | 6;
  description?: React.ReactNode;
}

const CardWrapped: React.FC<CardProps & Omit<JSX.IntrinsicElements['div'], 'title'>> = ({
  title,
  level = 2,
  description,
  children,
  ...grommetCardProps
}) => (
  <GrommetCard className="card" background={palette.white} {...grommetCardProps}>
    {title && <Heading level={level}>{title}</Heading>}
    {description && (
      <Paragraph margin={{ top: '0' }} fill>
        {description}
      </Paragraph>
    )}
    {children}
  </GrommetCard>
);

export const Card = styled(CardWrapped)(({ theme }) => ({
  padding: '32px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: 'none',
  border: `1px solid ${palette['gray-200']}`,
  color: palette.black,
  // Dissolve card border on small screens
  [theme.device.small]: {
    border: 'none',
    borderRadius: '0',
  },
}));
