import React from 'react';
import styled from 'styled-components';

import { theme } from '../Theme/theme';
import { clamp } from '../util/math';

const calculateScoreBackground = (score: number) => {
  if (score < 60) {
    return {
      background: theme.palette['red-600'],
      label: theme.palette['red-800'],
    };
  }

  if (score < 75) {
    return {
      background: theme.palette['yellow-600'],
      label: theme.palette['yellow-800'],
    };
  }

  return {
    background: theme.palette['green-600'],
    label: theme.palette['green-800'],
  };
};

export interface SiteScoreProps {
  /**
   * Numerical site score. Will get clamped bewtween 0-100.
   */
  score: number;
  /**
   * Render SiteScore in compact variant
   */
  compact?: boolean;
  /**
   * Custom function to calculate background color and label overlay backround from score
   */
  scoreColor?: (score: number) => { background: string; label: string };
}

const StyledSiteScore = styled.div<SiteScoreProps>(({ compact, score, scoreColor = calculateScoreBackground }) => {
  const { background, label } = scoreColor(score);
  const length = compact ? '3.5rem' : '5.5rem';

  return {
    backgroundColor: background,
    borderRadius: 8,
    color: theme.palette.white,
    width: length,
    height: length,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',

    // eslint-disable-next-line @typescript-eslint/naming-convention
    '& div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    // eslint-disable-next-line @typescript-eslint/naming-convention
    '& div:first-child': {
      flex: 1,
      fontSize: '2.25rem',
      fontWeight: 300,
      paddingTop: compact ? 0 : '0.5rem',

      ...(!compact
        ? {
            [theme.device.small]: {
              fontSize: '3rem',
              fontWeight: 800,
            },
          }
        : {}),
    },

    // eslint-disable-next-line @typescript-eslint/naming-convention
    '& div:nth-child(2)': {
      backgroundColor: label,
      fontSize: '.75rem',
      fontWeight: 500,
      letterSpacing: '0.05rem',
      textTransform: 'uppercase',
    },
  };
});

/**
 * Simple SiteScore widget
 */
export const SiteScore: React.FC<SiteScoreProps> = ({
  score: rawScore,
  compact = false,
  scoreColor = calculateScoreBackground,
}: SiteScoreProps) => {
  const score = clamp(rawScore);
  return (
    <StyledSiteScore {...{ score, compact, scoreColor }}>
      <div aria-label="Site Score">{score}</div>
      {!compact && <div aria-hidden>Site Score</div>}
    </StyledSiteScore>
  );
};
