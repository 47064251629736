import { Box, BoxTypes, Button as GrButton, Layer, LayerProps } from 'grommet';
import React, { HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

import { Icon } from '../Icon/Icon';
import { useViewportSize } from '../util/hooks';

const StyledLayer = styled(Layer)(({ theme }) => ({
  zIndex: theme.zIndex.toast,
}));

const StyledToast = styled(Box)(({ theme }) => ({
  boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.15)',
  color: theme.palette.white,
  fontSize: '1rem',
  lineHeight: 1.5,
  fontWeight: 500,
  backgroundColor: theme.palette.black,
  maxWidth: theme.global?.size?.medium,
  minWidth: theme.global?.size?.small,

  [theme.device.small]: {
    maxWidth: 'none',
    minWidth: 'none',
    width: '100%',
  },
}));

export type ToastPosition =
  | 'bottom'
  | 'bottom-left'
  | 'bottom-right'
  | 'center'
  | 'hidden'
  | 'left'
  | 'right'
  | 'top'
  | 'top-left'
  | 'top-right';

export interface ToastNotificationProps extends BoxTypes {
  /**
   * This is a description of the children prop
   */
  children?: ReactNode;
  /**
   * Close toast callback handler function.
   */
  onClose?: () => void | Promise<void>;
  /**
   * Toast Position
   */
  position?: ToastPosition;
  /**
   * Override existing layer props
   */
  layerProps?: LayerProps & HTMLAttributes<HTMLDivElement>;
  /**
   * Override toast wrapper props
   */
  wrapperProps?: BoxTypes;
}

/**
 * Toast Notification Component. Will animate into and out of position overlaying
 * the application. ToastNotification should controlled from your application.
 */
export const ToastNotification: React.FC<ToastNotificationProps> = ({
  children,
  onClose,
  position = 'bottom-left',
  layerProps,
  wrapperProps,
  ...rest
}: ToastNotificationProps) => {
  const size = useViewportSize();

  return (
    <StyledLayer
      modal={false}
      responsive={false}
      plain
      {...layerProps}
      position={position}
      full={size === 'small' ? 'horizontal' : undefined}
    >
      <Box pad="medium" {...wrapperProps}>
        <StyledToast
          data-cy="toastNotification"
          round="8px"
          pad="small"
          justify="between"
          direction="row"
          align="center"
          gap="small"
          role="alert"
          aria-live="polite"
          {...rest}
        >
          <Box pad="small">{children}</Box>
          <GrButton color="inherit" icon={<Icon type="close" />} onClick={onClose} />
        </StyledToast>
      </Box>
    </StyledLayer>
  );
};
