import { TextArea as GrTextArea } from 'grommet';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { fieldError, fieldId } from '../FormField/field-id-generator';
import { FormField, FormFieldProps } from '../FormField/FormField';
import { StyledCSS } from '../styled-components';
import { SuggestionType } from '../TextInput/TextInput';

export interface TextAreaProps extends FormFieldProps {
  /**
   * Name of the input.
   */
  name: string;
  /**
   * Input placeholder text
   */
  placeholder?: string;
  /**
   * Flag to reverse position of input icon
   */
  reverse?: boolean;
  /**
   * Input value. Setting will force input to be controlled.
   */
  value?: string;

  /**
   * adds a line of info text below the label and above the input.
   *  */
  info?: ReactNode;

  /**
   * Extra styled-components styles to add to the FormField
   */
  formFieldStyles?: StyledCSS;
  suggestions?: SuggestionType[];
  onSelect?: any;
}

const StyledTextArea = styled(GrTextArea)(({ theme, 'aria-invalid': ariaInvalid }) => ({
  fontSize: '1rem',
  fontWeight: 400,
  padding: 10,
  background: theme.palette.white,
  border: `1px solid ${theme.palette['gray-500']}`,
  borderRadius: '.5rem',

  // placeholder
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&::placeholder': {
    color: theme.palette['gray-600'],
    fontStyle: 'italic',
  },

  // Invalid style
  ...(ariaInvalid
    ? {
        color: theme.palette['red-800'],
        borderColor: theme.palette['red-800'],
      }
    : {}),
}));

/**
 * Basic TextInput form component.
 * Accepts all [FormField](#formfield) and standard input props. Pass `value` prop to control.
 * Automatically handles `aria-required`, `aria-invalid` and `aria-describedby` FormField error
 * by generating id from `name` prop.
 */
export const TextArea = React.forwardRef<
  HTMLTextAreaElement,
  TextAreaProps & Omit<JSX.IntrinsicElements['textarea'], 'onSelect' | 'size' | 'placeholder' | 'enterKeyHint'>
>(
  (
    {
      name,

      label,
      required,
      error,
      onFocus,
      onBlur,
      formFieldStyles,
      'aria-describedby': ariaDescribedBy,
      requiredLabel,
      info,
      ...rest
    }: TextAreaProps & Omit<JSX.IntrinsicElements['textarea'], 'onSelect' | 'size' | 'placeholder' | 'enterKeyHint'>,
    ref,
  ) => {
    const isInvalid = !!error;
    const describedBy = `${ariaDescribedBy || ''} ${error ? fieldError(name) : ''}`;

    return (
      <FormField
        formFieldStyles={formFieldStyles}
        name={name}
        label={label}
        required={required}
        requiredLabel={requiredLabel}
        error={error}
        info={info}
      >
        <div style={{ position: 'relative' }}>
          <StyledTextArea
            name={name}
            aria-invalid={isInvalid}
            aria-required={required}
            aria-describedby={describedBy}
            {...rest}
            id={fieldId(name)}
            ref={ref}
          />
        </div>
      </FormField>
    );
  },
);
