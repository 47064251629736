import { TextInput as GrTextInput } from 'grommet';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { fieldError, fieldId } from '../FormField/field-id-generator';
import { FormField, FormFieldProps } from '../FormField/FormField';
import { StyledCSS } from '../styled-components';

export type SuggestionType =
  | {
      label: ReactNode;
      value: string;
    }
  | string;

export interface TextInputProps extends FormFieldProps {
  /**
   * Name of the input.
   */
  name: string;
  /**
   * Input placeholder text
   */
  placeholder?: string;
  /**
   * Optional icon to display in TextInput context. Defaults to displaying
   * at the start of the input. Set `reverse` prop to `true` to display at end.
   */
  icon?: JSX.Element;
  /**
   * Flag to reverse position of input icon
   */
  reverse?: boolean;
  /**
   * Input value. Setting will force input to be controlled.
   */
  value?: string;

  /**
   * adds a line of info text below the label and above the input.
   *  */
  info?: ReactNode;

  /**
   * Whether this is a plain input with no border or outline.
   */
  plain?: boolean | 'full';

  /**
   * Extra styled-components styles to add to the FormField
   */
  formFieldStyles?: StyledCSS;
  suggestions?: SuggestionType[];
  onSelect?: any;
  autoFocus?: boolean;
}

const StyledTextInput = styled(GrTextInput)(({ theme, 'aria-invalid': ariaInvalid, icon, reverse }) => ({
  fontSize: '1rem',
  fontWeight: 400,
  padding: 10,
  background: theme.palette.white,
  border: `1px solid ${theme.palette['gray-500']}`,
  borderRadius: '.5rem',

  // placeholder
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&::placeholder': {
    color: theme.palette['gray-600'],
    fontStyle: 'italic',
  },

  // Invalid style
  ...(ariaInvalid
    ? {
        color: theme.palette['red-800'],
        borderColor: theme.palette['red-800'],
      }
    : {}),

  // Icon Spacing (mostly)
  ...(icon
    ? reverse
      ? {
          paddingRight: 44,
        }
      : {
          paddingLeft: 44,
        }
    : {}),
}));

/**
 * Basic TextInput form component.
 * Accepts all [FormField](#formfield) and standard input props. Pass `value` prop to control.
 * Automatically handles `aria-required`, `aria-invalid` and `aria-describedby` FormField error
 * by generating id from `name` prop.
 */
export const TextInput = React.forwardRef<
  HTMLInputElement,
  TextInputProps & Omit<JSX.IntrinsicElements['input'], 'onSelect' | 'size' | 'placeholder' | 'enterKeyHint' | 'width'>
>(
  (
    {
      name,

      label,
      required,
      error,
      onFocus,
      onBlur,
      icon,
      formFieldStyles,
      'aria-describedby': ariaDescribedBy,
      info,
      requiredLabel,
      autoFocus,
      ...rest
    }: TextInputProps &
      Omit<JSX.IntrinsicElements['input'], 'onSelect' | 'size' | 'placeholder' | 'enterKeyHint' | 'width'>,
    ref,
  ) => {
    const isInvalid = !!error;
    const describedBy = `${ariaDescribedBy || ''} ${error ? fieldError(name) : ''}`;

    return (
      <FormField
        formFieldStyles={formFieldStyles}
        name={name}
        label={label}
        required={required}
        requiredLabel={requiredLabel}
        error={error}
        info={info}
      >
        <div style={{ position: 'relative' }}>
          <StyledTextInput
            name={name}
            aria-invalid={isInvalid}
            aria-required={required}
            aria-describedby={describedBy}
            {...rest}
            id={fieldId(name)}
            ref={ref}
            icon={icon ? <div /> : undefined}
            autoFocus={autoFocus}
          />
          {/* buttons wouldn't work as icons so we moved them outside of the text component */}
          <div style={{ position: 'absolute', right: 12, display: 'flex', top: '50%', transform: 'translateY(-50%)' }}>
            {icon}
          </div>
        </div>
      </FormField>
    );
  },
);
