import React, { ReactNode } from 'react';
import styled, { CSSObject, DefaultTheme, useTheme } from 'styled-components';

import { palette } from '../Theme/palette';

export enum StatusType {
  SUCCESS = 'success',
  INFO = 'info',
  DEFAULT = 'default',
  WARNING = 'warning',
  ERROR = 'error',
}
export type StatusSizeType = 'small' | 'large';
export type StatusVariantType = 'default' | 'uppercase';

export interface StatusProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'label'> {
  /**
   * Status label
   */
  label: string | ReactNode;
  /**
   * Status type
   */
  status?: StatusType;
  /**
   * Status size type = defaults to small
   */
  size?: StatusSizeType;

  /**
   * Status size type = defaults to uppercase
   */
  variant?: 'default' | 'uppercase';
}

function getSizeStyle(theme: DefaultTheme, size: StatusSizeType): CSSObject {
  switch (size) {
    case 'large': {
      return {
        fontSize: theme.text?.large?.size ?? '1.125rem',
        fontWeight: 700,
        borderRadius: theme.text?.large?.height ?? '1.25rem',
        lineHeight: theme.text?.large?.height ?? '1.25rem',
      };
    }
    case 'small':
    default: {
      return {
        fontSize: theme.text?.small?.size ?? '0.75rem',
        fontWeight: 500,
        borderRadius: theme.text?.small?.height ?? '1rem',
        lineHeight: theme.text?.small?.height ?? '1rem',
      };
    }
  }
}

function getStatusStyle(theme: DefaultTheme, status: StatusType): CSSObject {
  switch (status) {
    case StatusType.SUCCESS: {
      return {
        backgroundColor: (theme?.global?.colors?.successBg as string) ?? palette['green-200'],
        color: (theme?.global?.colors?.successFg as string) ?? palette['green-800'],
      };
    }
    case StatusType.INFO: {
      return {
        backgroundColor: (theme?.global?.colors?.infoBg as string) ?? palette['purple-100'],
        color: (theme?.global?.colors?.infoFg as string) ?? palette['purple-900'],
      };
    }
    case StatusType.WARNING: {
      return {
        backgroundColor: (theme?.global?.colors?.warningBg as string) ?? palette['yellow-200'],
        color: (theme?.global?.colors?.warningFg as string) ?? palette['yellow-800'],
      };
    }
    case StatusType.ERROR: {
      return {
        backgroundColor: (theme?.global?.colors?.errorBg as string) ?? palette['red-200'],
        color: (theme?.global?.colors?.errorFg as string) ?? palette['red-800'],
      };
    }
    default: {
      return {
        backgroundColor: (theme?.global?.colors?.defaultBg as string) ?? palette['gray-100'],
        color: (theme?.global?.colors?.defaultFg as string) ?? palette['gray-600'],
      };
    }
  }
}

const DivComponent = styled.div<{
  theme: DefaultTheme;
  status: StatusType;
  variant: StatusVariantType;
  size: StatusSizeType;
}>`
  display: inline-block;
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
  ${(props) => getStatusStyle(props.theme, props.status)}
  ${(props) => (props.variant === 'uppercase' ? { textTransform: 'uppercase' } : {})}
  ${(props) => getSizeStyle(props.theme, props.size)}
`;

/**
 * Basic status indicator label component
 */
export const Status: React.FC<StatusProps> = ({
  label,
  status = StatusType.DEFAULT,
  size = 'small',
  variant = 'uppercase',
  ...rest
}: StatusProps) => {
  const theme = useTheme();
  return (
    <DivComponent theme={theme} variant={variant} status={status} size={size} {...rest}>
      {label}
    </DivComponent>
  );
};
