import * as React from 'react';

function SvgIconStar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        strokeWidth="1.5"
        d="M12.454 16.07L12 15.724l-.454.346-4.93 3.756 2.086-5.563.198-.526-.45-.337-4.2-3.15h5.79l.171-.513L12 4.372l1.789 5.365.17.513h5.791l-4.2 3.15-.45.337.198.526 2.086 5.563-4.93-3.756z"
      />
    </svg>
  );
}

export default SvgIconStar;
