import * as React from 'react';

function SvgIconCurrency(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M5.75 10.625C6.78553 10.625 7.625 9.78553 7.625 8.75M5.75 14.375C6.78553 14.375 7.625 15.2145 7.625 16.25M18.25 10.625C17.2145 10.625 16.375 9.78553 16.375 8.75M18.25 14.375C17.2145 14.375 16.375 15.2145 16.375 16.25M5.125 8.125H18.875V16.875H5.125V8.125ZM12 15C13.0355 15 13.875 13.8807 13.875 12.5C13.875 11.1193 13.0355 10 12 10C10.9645 10 10.125 11.1193 10.125 12.5C10.125 13.8807 10.9645 15 12 15Z"
        strokeWidth="1.33929"
      />
    </svg>
  );
}

export default SvgIconCurrency;
