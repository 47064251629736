import { DataTable as GrommetDataTable, DataTableProps as GrommetDataTableProps } from 'grommet';
import React from 'react';
import { useTheme } from 'styled-components';

export interface DataTableProps<T> extends GrommetDataTableProps<T> {
  isStriped?: boolean;
}

export function DataTable<TRowType>({ isStriped = false, ...props }: DataTableProps<TRowType>) {
  const theme = useTheme();

  const gray = theme.palette['gray-50'];

  // need to pass generic to GrommetDataTable to avoid TS error
  // no border
  return (
    <GrommetDataTable
      {...props}
      background={
        isStriped
          ? {
              header: 'transparent',
              body: ['transparent', gray], // Alternating row colors
            }
          : undefined
      }
    />
  );
}
