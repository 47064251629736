import * as React from 'react';

function IconAbilityCognitive(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width="32"
      height="32"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="28" cy="28" r="28" fill="#E7DEFC" />
      <path
        d="M25.2567 35.5135C26.0823 35.873 26.7888 36.4348 27.3014 37.1395C27.814 37.8442 28.1136 38.6655 28.1685 39.5166C28.2234 40.3676 28.0316 41.2167 27.6134 41.9741C27.1952 42.7314 26.5661 43.3688 25.7927 43.8188C25.0193 44.2688 24.1304 44.5146 23.22 44.5303C22.3097 44.546 21.4117 44.3309 20.6212 43.9079C19.8306 43.4848 19.1769 42.8695 18.7292 42.1271"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7734 26.8437C25.0694 27.3017 24.2524 27.5843 23.3995 27.6649C22.5466 27.7455 21.6859 27.6215 20.8983 27.3046C20.1107 26.9876 19.4222 26.4881 18.8977 25.8532C18.3732 25.2183 18.0299 24.4688 17.9001 23.6753"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.3919 34.3969C20.7024 33.9339 21.1091 33.5335 21.5873 33.22C22.0655 32.9065 22.6052 32.6865 23.1739 32.5732C23.7425 32.46 24.3281 32.4558 24.8953 32.5611"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.3012 34.3969C35.9907 33.9339 35.584 33.5335 35.1058 33.22C34.6277 32.9065 34.0879 32.6865 33.5193 32.5732C32.9506 32.46 32.365 32.4558 31.7978 32.5611"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.2248 24.6867C13.877 24.148 13.6614 23.5438 13.5937 22.9175C13.5259 22.2913 13.6077 21.6588 13.8331 21.0658C14.0584 20.4728 14.4218 19.9341 14.8968 19.4888C15.3718 19.0434 15.9467 18.7026 16.5797 18.4908C17.2127 18.279 17.888 18.2017 18.5569 18.2644C19.2257 18.3271 19.8713 18.5282 20.447 18.8533C21.0226 19.1783 21.5139 19.6191 21.8852 20.1438C22.2565 20.6685 22.4987 21.2639 22.594 21.8869"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8483 18.3197C16.5937 17.6077 16.616 16.8345 16.9112 16.1364C17.2064 15.4383 17.7555 14.8603 18.4616 14.5045C19.1676 14.1487 19.9852 14.0379 20.77 14.1917C21.5549 14.3455 22.2564 14.754 22.7508 15.345"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6737 32.4303C15.7075 32.4369 14.7648 32.1511 13.9861 31.6154C13.2073 31.0798 12.6342 30.323 12.3519 29.4577C12.0696 28.5923 12.0933 27.6648 12.4195 26.8131C12.7457 25.9614 13.3569 25.2312 14.1622 24.7311"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.7016 36.7557C12.9289 35.9788 12.4576 34.9813 12.3636 33.9234C12.2696 32.8655 12.5583 31.8087 13.1833 30.923"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7094 42.1408C17.3701 42.1402 16.0859 41.6418 15.1389 40.755C14.1919 39.8683 13.6596 38.6658 13.6589 37.4117C13.6594 37.1924 13.6752 36.9734 13.7063 36.7561"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.1777 41.2404V13.075"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4314 14.1247C20.457 13.1787 20.8764 12.2795 21.6 11.6189C22.3236 10.9583 23.2942 10.5886 24.3048 10.5886C25.3154 10.5886 26.286 10.9583 27.0097 11.6189C27.7333 12.2795 28.1526 13.1787 28.1782 14.1247"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.178 23.1819C28.0659 22.195 27.5775 20.949 25.7732 21.243"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.6976 35.7406C29.9286 36.1575 29.2907 36.757 28.8483 37.4785C28.4059 38.2 28.1746 39.0181 28.1778 39.8501C28.1809 40.6821 28.4184 41.4985 28.8663 42.2171C29.3142 42.9357 29.9567 43.5309 30.7287 43.9426C31.5008 44.3544 32.3753 44.5681 33.2638 44.5622C34.1523 44.5564 35.0234 44.3311 35.7892 43.9092C36.5551 43.4873 37.1884 42.8837 37.6255 42.1593"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.5823 26.8831C31.2867 27.3397 32.1035 27.6212 32.9559 27.7012C33.8084 27.7811 34.6686 27.6569 35.4557 27.3401C36.2429 27.0233 36.9311 26.5244 37.4558 25.8903C37.9806 25.2561 38.3246 24.5074 38.4556 23.7146"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.131 24.7314C42.4788 24.1928 42.6944 23.5885 42.7621 22.9622C42.8299 22.336 42.7481 21.7035 42.5227 21.1105C42.2973 20.5175 41.934 19.9788 41.459 19.5335C40.9839 19.0881 40.4091 18.7472 39.7761 18.5355C39.1431 18.3237 38.4677 18.2464 37.7989 18.3091C37.13 18.3718 36.4844 18.5729 35.9088 18.898C35.3332 19.223 34.8419 19.6638 34.4706 20.1885C34.0992 20.7132 33.8571 21.3086 33.7617 21.9317"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.5071 18.3501C39.7604 17.6384 39.7373 16.8657 39.4417 16.1684C39.146 15.471 38.597 14.8938 37.8913 14.5384C37.1856 14.1831 36.3686 14.0724 35.5843 14.226C34.8 14.3795 34.0988 14.7874 33.6042 15.3778"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.6816 32.4558C40.6479 32.4624 41.5906 32.1766 42.3694 31.6409C43.1481 31.1053 43.7213 30.3485 44.0036 29.4832C44.2859 28.6178 44.2622 27.6903 43.936 26.8386C43.6098 25.9869 42.9985 25.2567 42.1933 24.7566"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.6543 36.7861C43.4274 36.0094 43.8989 35.0118 43.9929 33.9538C44.0869 32.8958 43.798 31.8389 43.1726 30.9534"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.6458 42.1711C38.309 42.1711 38.9657 42.0488 39.5785 41.8111C40.1912 41.5735 40.748 41.2251 41.217 40.786C41.6859 40.3468 42.0579 39.8255 42.3118 39.2517C42.5656 38.678 42.6962 38.063 42.6962 37.442C42.6956 37.2227 42.6798 37.0037 42.6489 36.7864"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.9246 14.1575C35.9378 13.6734 35.8473 13.1919 35.6585 12.7411C35.4698 12.2903 35.1865 11.8795 34.8256 11.5328C34.4646 11.1862 34.0332 10.9106 33.5567 10.7225C33.0803 10.5344 32.5684 10.4375 32.0513 10.4375C31.5342 10.4375 31.0224 10.5344 30.5459 10.7225C30.0695 10.9106 29.638 11.1862 29.2771 11.5328C28.9161 11.8795 28.6329 12.2903 28.4441 12.7411C28.2554 13.1919 28.1649 13.6734 28.178 14.1575"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.1777 23.1821C28.2923 22.1952 28.7832 20.9796 30.5821 21.2736"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconAbilityCognitive;
