import * as React from 'react';

function SvgIconNewTab(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.8182 8.54545C11.8182 10.6756 11.8182 11.8699 11.8182 14H2V4.18182H7.45455M9.09091 2H14M14 2V6.90909M14 2L6.90909 9.09091"
        stroke="currentColor"
      />
    </svg>
  );
}

export default SvgIconNewTab;
