import * as React from 'react';

function IconNavigation(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="28" cy="28" r="28" fill="#E7DEFC" />
      <path
        d="M28.0327 17.8535V38.0008"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.3848 21.0391V40.9101"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M20.6182 21.0391V40.9101"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M42.3154 38.0007V17.5L35.4676 20.5044L28.424 17.5L20.5979 20.5044L13.75 17.5V38.0007L20.5979 41.1819L28.424 38.0007L35.4676 41.1819L42.3154 38.0007Z"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconNavigation;
