import { Box, FormField as GrFormField, FormFieldProps as GrFormFieldProps } from 'grommet';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { StyledCSS } from '../styled-components';
import { fieldError, fieldId } from './field-id-generator';

export interface FormFieldProps extends GrFormFieldProps {
  /**
   * The input to wrapped as a form field.
   */
  children?: ReactNode;
  /**
   * Name of the input field. Used to generate aria attributes and link label.
   */
  name?: string;
  /**
   * Field label content
   */
  label?: ReactNode;
  /**
   * Whether field is required or not. Adds required asterisk to label.
   */
  required?: boolean;
  /**
   * Field error structure. See [ErrorMessage](#errormessage) for more context
   *
   * TODO: strongly type this. EmailDeveloper.tsx uses a `FieldError` from react-hook form
   * that we may not want to pull into inclusive. probably change the error it uses there to fit a
   * type such as `Error | string | undefined` here.
   */
  error?: any;

  /**
   * Info text to display below the label but above the input field.
   */
  info?: ReactNode;

  /**
   * Required labeling. Leave blank for default label. Defaults to an asterisk.
   */
  requiredLabel?: string;

  formFieldStyles?: StyledCSS;
}

const CSS_PROPS = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '& label': {
    fontSize: '1rem',
    fontWeight: 700,
    margin: 0,
    marginBottom: '.5rem',
  },
};

const FormFieldWrapper = styled(GrFormField)<FormFieldProps>(({ formFieldStyles, ...otherProps }) =>
  typeof formFieldStyles === 'function'
    ? formFieldStyles({ ...otherProps, css: CSS_PROPS })
    : { ...formFieldStyles, ...CSS_PROPS },
);

/**
 * Wrapper component for form fields to provide consistent, accessible labeling and style.
 * Provides field label and accessible error message. All form components in this library
 * are wrapped with a `<FormField />`. If you need to create custom form input components
 * in your app wrap them with this.
 */
export const FormField: React.FC<FormFieldProps> = ({
  children,
  required,
  name,
  label,
  error,
  requiredLabel,
  info,
  ...rest
}: FormFieldProps) => (
  <FormFieldWrapper
    {...rest}
    htmlFor={fieldId(name)}
    name={name}
    label={
      label && required ? (
        <>
          {label}
          {requiredLabel ? (
            <span
              style={{ paddingLeft: '.25rem', fontWeight: 'normal', fontStyle: 'italic', fontSize: '.9rem' }}
              className="required"
              aria-hidden
            >
              {requiredLabel}
            </span>
          ) : (
            <sup className="required" aria-hidden>
              *
            </sup>
          )}
        </>
      ) : (
        label
      )
    }
    required={required}
  >
    {info && <div style={{ paddingBottom: '1rem' }}>{info}</div>}
    {children}
    {error && (
      <Box margin={{ vertical: 'xsmall' }}>
        <ErrorMessage id={fieldError(name)} error={error} />
      </Box>
    )}
  </FormFieldWrapper>
);
