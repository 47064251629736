import * as React from 'react';

function SvgIconSolutionHelpDesk(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
      <path
        fill="#000"
        d="M23.255 25.846c1.537 0 2.745-1.295 2.745-2.845v-3.332c0-3.295-1.828-6.165-4.501-7.538v1.44c1.951 1.253 3.261 3.507 3.261 6.098v3.332c0 .903-.695 1.596-1.506 1.596h-6.376c-2.892 0-5.315-2.249-5.703-5.205H9.926c.396 3.616 3.34 6.454 6.952 6.454h6.377Z"
      />
      <path
        fill="#000"
        d="M6 14.452c0-4.616 3.587-8.397 8.06-8.397h1.062c1.34 0 2.587.39 3.647 1.066l-.702 1.032a5.534 5.534 0 0 0-2.945-.85H14.06c-3.745 0-6.82 3.18-6.82 7.15v3.33c0 .904.696 1.597 1.507 1.597h6.376c3.159 0 5.757-2.682 5.757-6.038 0-.489-.055-.963-.16-1.416l1.209-.281c.125.545.19 1.113.19 1.697 0 4.003-3.11 7.287-6.996 7.287H8.746C7.208 20.63 6 19.334 6 17.784v-3.332Z"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M14.06 14.799a.622.622 0 0 1-.62-.625V9.177c0-.345.277-.624.62-.624.342 0 .62.28.62.624v4.997a.622.622 0 0 1-.62.625ZM20.672 11.26a2.074 2.074 0 0 0 2.067-2.082c0-1.15-.925-2.083-2.067-2.083a2.074 2.074 0 0 0-2.066 2.083c0 1.15.925 2.082 2.066 2.082Zm0 1.249c1.826 0 3.307-1.492 3.307-3.331 0-1.84-1.48-3.332-3.307-3.332-1.826 0-3.306 1.492-3.306 3.332 0 1.84 1.48 3.33 3.306 3.33Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        d="M14.886 16.673a.83.83 0 0 1-.827.833.83.83 0 0 1-.826-.833c0-.46.37-.833.826-.833a.83.83 0 0 1 .827.833Z"
      />
    </svg>
  );
}

export default SvgIconSolutionHelpDesk;
