import React, { MouseEvent, useCallback, useMemo } from 'react';
import styled from 'styled-components';

import { Button } from '../Button/Button';
import { baseNodeStyle } from './style';
import { PageNodeProps } from './types';

const StyledPageNodeButton = styled(Button)({
  ...baseNodeStyle,
  padding: 0,
});

export const PageNode: React.FC<PageNodeProps> = ({
  page,
  current,
  total,
  params,
  onClick = () => undefined,
  btnProps,
}: PageNodeProps) => {
  const isActive = page === current;
  const nodeProps = useMemo(() => ({ page, current, total, params }), [page, current, total, params]);
  const clickHandler = useCallback(
    (event: MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
      event.preventDefault();
      onClick(nodeProps);
    },
    [nodeProps, onClick],
  );
  const moreBtnProps = useMemo(() => {
    if (typeof btnProps === 'function') {
      return btnProps(nodeProps);
    }
    return btnProps;
  }, [btnProps, nodeProps]);

  return (
    <StyledPageNodeButton
      data-cy="page-node"
      variant={isActive ? 'primary' : 'text'}
      onClick={isActive ? undefined : clickHandler}
      a11yTitle={`Page ${page}`}
      {...moreBtnProps}
    >
      {page}
    </StyledPageNodeButton>
  );
};
