import { Paragraph as GrParagraph, ParagraphProps as GrParagraphProps } from 'grommet';
import { MarginType, TextAlignType } from 'grommet/utils';
import React, { ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

export type ParagraphVariant = 'regular' | 'medium' | 'bold' | 'italic';

const variantStyle: Record<ParagraphVariant, CSSObject> = {
  regular: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  medium: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  bold: {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: 1.5,
  },
  italic: {
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: 1.5,
    fontStyle: 'italic',
  },
};

const VariantComponents = {
  regular: styled(GrParagraph)(variantStyle.regular),
  medium: styled(GrParagraph)(variantStyle.medium),
  bold: styled(GrParagraph)(variantStyle.bold),
  italic: styled(GrParagraph)(variantStyle.italic),
};

export interface ParagraphIProps extends GrParagraphProps {
  id?: string;
  fill?: boolean;
}

export interface ParagraphProps
  extends Omit<ParagraphIProps, 'alignSelf' | 'color' | 'fill' | 'gridArea' | 'responsive' | 'size'> {
  /**
   * Custom label to be used by screen readers.
   * When provided, an aria-label will be added to the element.
   */
  a11yTitle?: string;
  /**
   * Paragraph content.
   */
  children?: ReactNode;
  /**
   * The amount of margin around the component.
   * An object can be specified to distinguish horizontal margin,
   * vertical margin, and margin on a particular side.
   */
  margin?: MarginType;
  /**
   * How to align the text inside the component.
   */
  textAlign?: TextAlignType;
  /**
   * Restrict the text to a single line and truncate with ellipsis if it is too long to all fit.
   * For truncate to be applied, Text needs to be contained within a layout component.
   */
  truncate?: boolean;
  /**
   * Pre-styled Paragraph variant
   */
  variant?: ParagraphVariant;
  fill?: boolean;
  color?: string;
}

/**
 * General use Paragraph component with predefined variant styles.
 */
export const Paragraph: React.FC<ParagraphProps> = ({ variant = 'regular', ...rest }: ParagraphProps) => {
  const ParagraphVariantComponent = VariantComponents[variant];

  return <ParagraphVariantComponent {...rest} responsive={false} fill />;
};
