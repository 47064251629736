import React from 'react';

import { BaseIconProps } from '../types';
// NOTE: We must run `> npm run svgr` to convert svgs to react components because @svgr/rollup
// doesn't play nicely with typescript at present
// NOTE: I've additionally determined that svgr is stripping svg attributes. We need to write our own
// conversion strip to create svg components without stripping various "stroke-*"" attributes.
import {
  IconArticle,
  IconBarChart,
  IconCalendar,
  IconCode,
  IconCompliance,
  IconConfigure,
  IconCopy,
  IconCurrency,
  IconDotsHorizontal,
  IconDownload,
  IconHide,
  IconMail,
  IconPrint,
  IconScan,
  IconShare,
  IconShow,
  IconStar,
  IconStatusCheckmark,
  IconStatusWarning,
  IconTrashcan,
  IconUser,
} from '.';

export type CustomIconType =
  | 'article'
  | 'bar-chart'
  | 'calendar'
  | 'code'
  | 'compliance'
  | 'configure'
  | 'copy'
  | 'currency'
  | 'dots-horizontal'
  | 'download'
  | 'hide'
  | 'mail'
  | 'print'
  | 'share'
  | 'scan'
  | 'show'
  | 'star'
  | 'status-checkmark'
  | 'status-warning'
  | 'trashcan'
  | 'user';

const customIconMap = {
  article: IconArticle,
  'bar-chart': IconBarChart,
  calendar: IconCalendar,
  code: IconCode,
  compliance: IconCompliance,
  configure: IconConfigure,
  copy: IconCopy,
  currency: IconCurrency,
  'dots-horizontal': IconDotsHorizontal,
  download: IconDownload,
  hide: IconHide,
  mail: IconMail,
  print: IconPrint,
  scan: IconScan,
  share: IconShare,
  show: IconShow,
  star: IconStar,
  'status-checkmark': IconStatusCheckmark,
  'status-warning': IconStatusWarning,
  trashcan: IconTrashcan,
  user: IconUser,
};

export type CustomIconProps = BaseIconProps;

export const CustomIcon: React.FC<CustomIconProps & JSX.IntrinsicElements['svg']> = ({
  type,
  color: stroke = 'currentColor',
  size = '1.5rem',
  a11yTitle,
  ...rest
}) => {
  const SvgComponent = customIconMap[type as CustomIconType];

  return <SvgComponent {...rest} aria-label={a11yTitle} stroke={stroke} width={size} height={size} />;
};
