import * as React from 'react';

function SvgIconContent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="28" cy="28" r="28" fill="#E7DEFC" />
      <line x1="29.625" y1="22.375" x2="44.375" y2="22.375" stroke="#7119E1" strokeWidth="1.25" strokeLinecap="round" />
      <line x1="29.625" y1="28.375" x2="44.375" y2="28.375" stroke="#7119E1" strokeWidth="1.25" strokeLinecap="round" />
      <line x1="29.625" y1="34.375" x2="44.375" y2="34.375" stroke="#7119E1" strokeWidth="1.25" strokeLinecap="round" />
      <path
        d="M12 30.4338L15.8245 26.873L18.7991 30.4338L20.9238 28.9831L24.1816 32.0164"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
      <circle cx="21.6101" cy="24.842" r="0.625" fill="#7119E1" stroke="#7119E1" strokeWidth="0.644921" />
      <rect
        x="11.375"
        y="21.375"
        width="13.7023"
        height="13.7023"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgIconContent;
