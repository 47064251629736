import { createGlobalStyle } from 'styled-components';

import MenloRegularWoff from './Menlo-Regular.woff';

const MenloFontFamily = createGlobalStyle`
  @font-face {
    font-family: 'Menlo Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Menlo Regular'), url(${MenloRegularWoff}) format('woff');
  }
`;

export default MenloFontFamily;
