import * as React from 'react';

function SvgIconSolutionAccessibilityStatement(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
      <path
        fill="#000"
        d="M8.941 21.14A2.941 2.941 0 0 1 6 18.2V8.787a2.941 2.941 0 0 1 2.941-2.942H23.06A2.941 2.941 0 0 1 26 8.788v9.411a2.941 2.941 0 0 1-2.941 2.942h-1.8l.623-1.177h1.177c.974 0 1.765-.79 1.765-1.765V8.788c0-.975-.79-1.765-1.765-1.765H8.94c-.974 0-1.765.79-1.765 1.765v9.411c0 .975.79 1.765 1.765 1.765H16.3l.73 1.177H8.94Z"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M9.137 10.356c0 .325.264.588.588.588h12.55a.588.588 0 1 0 0-1.176H9.724a.588.588 0 0 0-.588.588ZM9.137 12.709c0-.325.264-.588.588-.588h12.55a.588.588 0 1 1 0 1.176H9.724a.588.588 0 0 1-.588-.588ZM9.137 15.062c0-.325.264-.588.588-.588h3.922a.588.588 0 0 1 0 1.176H9.725a.588.588 0 0 1-.588-.588Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        d="M22.042 19.716v4.758c0 1.108-1.24 1.758-2.142 1.122l-.664-.467a.193.193 0 0 0-.223 0l-.663.467c-.902.636-2.142-.014-2.142-1.122v-4.758l1.167 1.408v3.35c0 .158.177.251.306.16l.663-.467a1.354 1.354 0 0 1 1.561 0l.664.467a.194.194 0 0 0 .306-.16v-3.241l1.167-1.517Z"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M19.168 20.944a2.745 2.745 0 1 0 0-5.49 2.745 2.745 0 0 0 0 5.49Zm0 1.177a3.922 3.922 0 1 0 0-7.844 3.922 3.922 0 0 0 0 7.844Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconSolutionAccessibilityStatement;
