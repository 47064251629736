import { Box } from 'grommet';
import React from 'react';
import styled from 'styled-components';

import { baseNodeStyle } from './style';

const StyledEllipsis = styled(Box)(({ theme }) => ({
  ...baseNodeStyle,
  color: theme.palette['purple-600'],
  paddingBottom: 8,
}));

export const Ellipsis: React.FC = () => <StyledEllipsis>&hellip;</StyledEllipsis>;
