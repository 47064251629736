import * as React from 'react';

function IconRuler(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={22} height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.5 4.50001L15 6.00001M10.5 7.50001L12 9.00001M7.5 10.5L9 12M4.5 13.5L6 15M1.56564 16.5657L5.43427 20.4344C5.63228 20.6324 5.73129 20.7314 5.84545 20.7685C5.94587 20.8011 6.05405 20.8011 6.15447 20.7685C6.26863 20.7314 6.36764 20.6324 6.56564 20.4344L20.4343 6.56573C20.6323 6.36772 20.7313 6.26872 20.7684 6.15455C20.801 6.05413 20.801 5.94596 20.7684 5.84554C20.7313 5.73137 20.6323 5.63237 20.4343 5.43436L16.5656 1.56573C16.3676 1.36772 16.2686 1.26872 16.1545 1.23163C16.054 1.199 15.9459 1.199 15.8455 1.23163C15.7313 1.26872 15.6323 1.36772 15.4343 1.56573L1.56564 15.4344C1.36764 15.6324 1.26863 15.7314 1.23154 15.8455C1.19891 15.946 1.19891 16.0541 1.23154 16.1546C1.26863 16.2687 1.36764 16.3677 1.56564 16.5657Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconRuler;
