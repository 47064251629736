import * as React from 'react';

function IconSectionCompliance(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width="32"
      height="32"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="28" cy="28" r="28" fill="#E7DEFC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.1818 11.3334C30.2923 11.3334 32.0031 13.0751 32.0031 15.2239C32.0031 17.3726 30.2923 19.1143 28.1818 19.1143C26.0712 19.1143 24.3605 17.3726 24.3605 15.2239C24.3605 13.0751 26.0712 11.3334 28.1818 11.3334ZM40.8258 22.2856L32.9102 23.6505C32.3956 23.7535 32.0191 24.2038 32.0031 24.7375V26.2414C31.9702 27.863 32.1155 29.4829 32.4366 31.0718L35.0216 42.4082C35.2977 43.3326 34.7856 44.3093 33.8784 44.5904C33.6352 44.6656 33.3783 44.6852 33.127 44.6476C32.3748 44.6166 31.7382 44.0722 31.5776 43.3236L28.5751 32.6983C28.5117 32.4727 28.2805 32.342 28.0589 32.4065C27.9201 32.4466 27.8117 32.5569 27.7723 32.6983L24.7699 43.3236C24.6093 44.0722 23.9727 44.6166 23.2205 44.6476C22.282 44.789 21.4086 44.1286 21.2697 43.1732C21.2328 42.9165 21.2521 42.6558 21.3259 42.4082L23.927 31.0964C24.2481 29.5075 24.3934 27.8875 24.3605 26.2659V24.7375C24.3589 24.1916 23.9791 23.7232 23.4533 23.6178L15.5378 22.2856C14.8281 22.1589 14.3536 21.4715 14.4781 20.749C14.6025 20.0265 15.2777 19.5434 15.9873 19.6701C15.9873 19.6701 24.8743 20.6999 28.1818 20.7081C31.4893 20.7163 40.3762 19.6619 40.3762 19.6619C41.0875 19.5353 41.7651 20.0207 41.8895 20.7449C42.0139 21.4691 41.5371 22.1589 40.8258 22.2856Z"
        fill="#7119E1"
      />
    </svg>
  );
}

export default IconSectionCompliance;
