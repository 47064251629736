import * as React from 'react';

function SvgIconAudioVideo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="28" cy="28" r="28" fill="#E7DEFC" />
      <rect
        x="28.75"
        y="34.8012"
        width="3.34671"
        height="6.02407"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinejoin="round"
      />
      <path
        d="M36.7902 30.3094L32.1846 34.5675C32.0778 34.6662 32.0171 34.805 32.0171 34.9504V40.4561C32.0171 40.6053 32.081 40.7473 32.1926 40.8463L36.7982 44.9305C37.1347 45.2289 37.6657 44.99 37.6657 44.5404V30.6924C37.6657 30.2376 37.1242 30.0007 36.7902 30.3094Z"
        stroke="#7119E1"
        strokeWidth="1.25"
      />
      <path
        d="M42.4851 32.3915C42.4851 32.3915 45.0922 34.502 45.0922 37.8335C45.0922 41.1649 42.4851 42.9939 42.4851 42.9939"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
      <path
        d="M40.7981 35.0421C40.7981 35.0421 42.1017 36.0974 42.1017 37.7631C42.1017 39.4288 40.7981 40.3433 40.7981 40.3433"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
      />
      <path
        d="M25.75 39H13.75V16H40.75V29"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line x1="14.375" y1="20.375" x2="40.125" y2="20.375" stroke="#7119E1" strokeWidth="1.25" strokeLinecap="round" />
      <path d="M13.75 34.5H25.75" stroke="#7119E1" strokeWidth="1.25" strokeLinecap="round" />
    </svg>
  );
}

export default SvgIconAudioVideo;
