import { Accordion as GrommetAccordion, AccordionExtendedProps, Box } from 'grommet';
import React, { useState } from 'react';
import styled, { DefaultTheme } from 'styled-components';

import { AccordionPanel } from '../AccordionPanel/AccordionPanel';
import { Heading } from '../Heading/Heading';
import { Text } from '../Text/Text';

interface AccordionsProps {
  heading: string;
  children: JSX.Element;
  'data-cy'?: string;
  actionLink?: JSX.Element;
  icon?: JSX.Element;
}

interface AccordionWrappedProps extends AccordionExtendedProps {
  accordions: AccordionsProps[];
  hasAnimation?: boolean;
  variant?: 'admin' | 'faq' | 'icon';
}

export const BoxWithChildren = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette['gray-100']}`,
  borderTopStyle: 'none',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '& > div': {
    border: 'none',
  },
}));

const AccordionElement: React.FC<AccordionWrappedProps> = ({
  accordions,
  hasAnimation = true,
  variant = 'admin',
  ...otherProps
}: AccordionWrappedProps) => {
  const [active, setActive] = useState<number[]>([]);

  return (
    <GrommetAccordion animate={hasAnimation} multiple {...otherProps} onActive={(val) => setActive(val)} gap="medium">
      {accordions.length &&
        accordions.map((accordion, ind) => {
          const isActive = active.includes(ind);
          return (
            <AccordionPanel
              data-cy={accordion['data-cy']}
              active={isActive}
              key={accordion.heading}
              heading={
                variant === 'admin' ? (
                  <>
                    {accordion.icon && <Box pad={{ left: 'medium' }}>{accordion.icon}</Box>}
                    <Heading level="2">{accordion.heading}</Heading>
                  </>
                ) : (
                  <Box style={{ alignItems: 'center' }} direction="row" pad={{ left: 'medium' }}>
                    {accordion.icon ?? undefined}
                    <Text>{accordion.heading}</Text>
                  </Box>
                )
              }
            >
              {isActive ? (
                <BoxWithChildren>
                  {accordion.children}
                  {accordion.actionLink && (
                    <Box direction="row" pad={{ bottom: 'medium', left: 'medium', top: 'medium' }}>
                      {accordion.actionLink}
                    </Box>
                  )}
                </BoxWithChildren>
              ) : (
                <></>
              )}
            </AccordionPanel>
          );
        })}
    </GrommetAccordion>
  );
};

const getAccordionStyles = (theme: DefaultTheme) => ({
  marginTop: '24px',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '& button:focus': {
    boxShadow: 'none',
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '& > div>div': {
    border: 'none',
  },
  [theme.device.small]: {
    padding: '0px 15px',
  },
});

export const Accordion = styled(AccordionElement)(({ theme }) => getAccordionStyles(theme));
