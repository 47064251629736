import * as React from 'react';

function IconSectionDescription(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width="32"
      height="32"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="28" cy="28" r="28" fill="#E7DEFC" />
      <rect x="26" y="24" width="4" height="15" rx="0.5" fill="#7119E1" />
      <circle cx="28" cy="18.5" r="2.5" fill="#7119E1" />
    </svg>
  );
}

export default IconSectionDescription;
