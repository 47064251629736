import { Grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';
import React from 'react';

import MenloFont from './font/menlo';
import { theme as defaultTheme, ThemeType } from './theme';

export interface ThemeProviderProps {
  /**
   *
   */
  children?: React.ReactNode;
  /**
   * Custom theme definition.
   */
  theme?: Partial<ThemeType>;
}

/**
 * Component library ThemeProvider.
 * Wrap your application with this component at the top level to provide
 * theme styling throughout your application.
 */
export const ThemeProvider: ({ theme, children }: ThemeProviderProps) => JSX.Element = ({
  theme = {},
  children,
}: ThemeProviderProps) => (
  <Grommet theme={deepMerge(defaultTheme, theme)}>
    {/* Load Globals */}
    <MenloFont />
    {children}
  </Grommet>
);
