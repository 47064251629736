import * as React from 'react';

function SvgIconCompliance(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M14.25 6.625H17.375V19.125H6.125V6.625H9.25M9.25 13.5L11.125 15.375L14.875 11.625M9.25 8.5H14.25V5.375H9.25V8.5Z"
        strokeWidth="1.33929"
        stroke="currentColor"
      />
    </svg>
  );
}

export default SvgIconCompliance;
