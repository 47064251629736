import React from 'react';
import styled from 'styled-components';

export interface UnorderedListProps {
  /**
   * Set as aria-labelledby prop on the ul tag
   */
  ariaLabelledBy?: string;
  /**
   * Array of React Components that will render in individual li tags.
   * E.g. an array of Text components
   */
  items: React.ReactNode[];
}

const StyledUnorderedList = styled('ul')(({ theme: { unorderedList } }) => unorderedList);

export const UnorderedList: React.FC<UnorderedListProps> = ({ items, ariaLabelledBy }: UnorderedListProps) => (
  <StyledUnorderedList aria-labelledby={ariaLabelledBy}>
    {items.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
  </StyledUnorderedList>
);
