import * as React from 'react';

import { IconProps } from '../types';

function SvgIconScroll({ a11yTitle, color = 'currentColor', ...rest }: IconProps) {
  return (
    <svg
      aria-label={a11yTitle}
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.5 5.625C5.11929 5.625 4 6.74429 4 8.125V8.75H4.625H7.65437L6.50628 16.7866L6.5 16.8306V16.875C6.5 18.2557 7.61929 19.375 9 19.375H16.5C17.8807 19.375 19 18.2557 19 16.875V16.25H18.375H15.3456L16.4937 8.21339L16.5 8.16942V8.125C16.5 6.74429 15.3807 5.625 14 5.625H6.5ZM6.5 6.875C6.03732 6.875 5.63336 7.12637 5.41723 7.5H7.58278C7.36664 7.12637 6.96268 6.875 6.5 6.875ZM8.66554 6.875H14C14.6769 6.875 15.2283 7.41314 15.2494 8.08498L14.0829 16.25H10.875H10.25V16.875C10.25 17.5654 9.69036 18.125 9 18.125C8.32303 18.125 7.77176 17.5869 7.75063 16.915L8.99372 8.21339L9 8.16942V8.125C9 7.66964 8.87826 7.24272 8.66554 6.875ZM16.5 18.125H11.1656C11.2774 17.9316 11.3642 17.7218 11.4212 17.5H17.5828C17.3666 17.8736 16.9627 18.125 16.5 18.125Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgIconScroll;
