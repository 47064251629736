import * as React from 'react';

function SvgIconArticle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        strokeWidth="1.5"
        d="M6 18.5V3h17v15.5a2.5 2.5 0 01-2.5 2.5h-17M16 7h3v4h-3V7zm-7 8h11H9zm0-4h4-4zm0-4h4-4zM6 18.5a2.5 2.5 0 01-5 0V7h5.025L6 18.5z"
      />
    </svg>
  );
}

export default SvgIconArticle;
