import { Paragraph } from 'grommet';
import React, { ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

import { Link } from '../Link/Link';

type CrumbType =
  | {
      title: JSX.Element | string;
      to?: string;
    }
  | JSX.Element;

export interface BreadCrumbsProps {
  'data-cy'?: string;
  crumbs: CrumbType[];
  separator?: ReactNode;
}

export type BreadCrumbWrapperProps = { children: ReactNode; dataCy?: string; className?: string };

const BreadCrumbWrapper: React.FC<BreadCrumbWrapperProps> = ({ children, dataCy, className }) => (
  <ol role="navigation" className={className} data-cy={dataCy}>
    {children}
  </ol>
);

const StyledBreadCrumbWrapper = styled(BreadCrumbWrapper)<{ children: ReactNode; dataCy: string; className?: string }>(
  ({ theme: { palette } }) => {
    const root: CSSObject = {
      display: 'flex',
      flexDirection: 'row',
      listStyleType: 'none',
      alignItems: 'flex-end',
      padding: 0,
      margin: 0,

      // eslint-disable-next-line @typescript-eslint/naming-convention
      '& li': {
        marginLeft: 4,
        p: {
          fontSize: '14px',
          lineHeight: 1.5,
          fontWeight: 400,
          marginBottom: 0,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&:first-child': {
          marginLeft: 0,
        },
      },
      // eslint-disable-next-line @typescript-eslint/naming-convention
      '& a': {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        '& span': {
          fontSize: '14px',
        },
        color: palette['gray-800'],
      },
    };
    return root;
  },
);

const renderCrumb = (crumb: { title: ReactNode; to?: string }) =>
  crumb.to ? <Link href={crumb.to}>{crumb.title}</Link> : crumb.title;

/* eslint-disable react/no-array-index-key */
export const BreadCrumbs: React.FC<BreadCrumbsProps> = ({
  'data-cy': dataCy = 'breadcrumbs',
  crumbs,
  separator = <Paragraph>/</Paragraph>,
}) => (
  <StyledBreadCrumbWrapper dataCy={dataCy}>
    {crumbs.reduce((items, crumb, idx) => {
      const item = (
        <li key={`crumb-${idx}`} aria-current={idx === crumbs.length - 1 ? 'page' : false}>
          <Paragraph>{'title' in crumb ? renderCrumb(crumb) : crumb}</Paragraph>
        </li>
      );
      items.push(item);
      if (idx < crumbs.length - 1) {
        items.push(
          <li key={`separator-${idx}`} aria-hidden>
            {separator}
          </li>,
        );
      }
      return items;
    }, [] as JSX.Element[])}
  </StyledBreadCrumbWrapper>
);
