import { Box, Button as GrButton, CheckBox, Layer, LayerProps } from 'grommet';
import { WidthType } from 'grommet/utils';
import React from 'react';
import FocusLock from 'react-focus-lock';
import styled, { CSSObject } from 'styled-components';

import { Heading } from '../Heading/Heading';
import { Icon } from '../Icon/Icon';

export interface ModalProps extends LayerProps {
  /**
   * Whether modal is open or not
   */
  isOpen?: boolean;
  /**
   * Modal Content
   */
  children?: React.ReactNode;
  /**
   * Callback close handler. Controls whether or not to display close button or stop showing again during session
   */
  onClose: (stopShowing: boolean) => void;
  /**
   * Override the close icon
   */
  closeIcon?: React.ReactElement;
  /**
   * Whether to show checkbox(set no longer show this modal during user session)
   */
  showAgainCheckBox?: boolean;
  /**
   * Modal title
   */
  title?: string;
  /**
   * Modal footer
   */
  footer?: React.ReactNode;

  width?: WidthType;
}

const StyledModalWrapper = styled(Box)(({ theme: { modal } }) => modal);

const StyledLayer = styled(Layer)(() => {
  const layerStyle: CSSObject = {
    borderRadius: '32px',
  };
  return layerStyle;
});

const StyledGrButton = styled(GrButton)(() => {
  const buttonStyle: CSSObject = {
    padding: '0px',
  };
  return buttonStyle;
});

const StyledHeader = styled(Box)(({ theme: { modalHeader } }) => modalHeader);
const StyledBody = styled(Box)(({ theme: { modalBody } }) => modalBody);
const StyledFooter = styled(Box)(({ theme: { modalFooter } }) => modalFooter);

export const Modal: React.FC<ModalProps & Omit<JSX.IntrinsicElements['div'], 'ref'>> = ({
  isOpen,
  children,
  onClose,
  closeIcon = <Icon type="close" />,
  title,
  footer = null,
  showAgainCheckBox = false,
  width,
  ...rest
}) => {
  const [checked, setChecked] = React.useState(false);

  if (!isOpen) {
    return null;
  }

  const onCloseWithDontShowAgain = () => {
    onClose(checked);
  };

  return (
    <FocusLock returnFocus>
      <StyledLayer {...rest} onEsc={onCloseWithDontShowAgain} onClickOutside={onCloseWithDontShowAgain}>
        <StyledModalWrapper>
          <Box alignContent="center" gap="medium" width={width}>
            <StyledHeader direction="row" justify="between" align="center" pad={{ bottom: 'small' }}>
              <Heading>{title}</Heading>
              <StyledGrButton
                color="inherit"
                data-cy="closeModalButton"
                icon={<Icon type="close" size="18px" />}
                onClick={onCloseWithDontShowAgain}
              />
            </StyledHeader>
            <StyledBody>{children}</StyledBody>
          </Box>
          {(footer || showAgainCheckBox) && (
            <StyledFooter className="FOOTER">
              {showAgainCheckBox && (
                <Box direction="row" justify="start" gap="small">
                  <Box margin={{ top: 'medium' }}>
                    <CheckBox
                      checked={checked}
                      label="Don't show again"
                      onChange={(event) => setChecked(event.target.checked)}
                    />
                  </Box>
                </Box>
              )}
              {footer}
            </StyledFooter>
          )}
        </StyledModalWrapper>
      </StyledLayer>
    </FocusLock>
  );
};
