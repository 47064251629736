import { Box, BoxExtendedProps } from 'grommet';
import React from 'react';

export type InteractiveBoxProps = BoxExtendedProps & { disabled?: boolean; isSelected?: boolean };

/**
 * Box component with additional fields for additional styling based on interactivity and toggling.
 * Accepts all [FormField](#formfield) and standard input props. Pass `value` prop to control.
 * Automatically handles `aria-required`, `aria-invalid` and `aria-describedby` FormField error
 * by generating id from `name` prop.
 */
export const InteractiveBox = ({
  disabled: _ = false,
  isSelected: __ = false,
  children,
  ...rest // eslint-disable-next-line react/jsx-no-undef
}: InteractiveBoxProps) => <Box {...rest}>{children}</Box>;
