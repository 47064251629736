import * as React from 'react';

function IconAbilityMotor(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width="32"
      height="32"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="28" cy="28" r="28" fill="#E7DEFC" />
      <path
        d="M29.8942 25.1126C32.3119 22.721 36.5936 18.7462 37.3942 18.1126C39.0001 16.8417 41.1649 15.5669 42.4493 16.1421C43.5001 16.6126 44.7419 18.1444 41.5702 20.6126C39.0001 22.6126 34.0974 28.1 32.9985 29.4045C32.1194 30.4481 30.7274 32.4484 30.1413 33.3181C32.0461 32.3759 36.1988 30.6121 37.3942 30.2742C38.9327 29.8394 41.5702 29.8394 41.5702 32.231C41.5702 33.7529 38.4932 33.9703 35.1964 35.7096C31.46 38.1012 28.1632 39.1883 28.1632 39.1883C28.1632 39.1883 24.4269 40.2754 20.4707 39.1883"
        stroke="#7119E1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 32.6127C12.0699 31.0784 12.7616 27.7924 13.6774 26.0373C14.9355 23.6264 19.3387 18.6949 20.5968 17.7086C21.4355 17.051 22.2175 16.6127 23.3226 16.6127C24.1613 16.6127 25 17.4894 25 18.3661"
        stroke="#7119E1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.353 21.789C23.2593 20.6514 25.2811 18.2397 26.1177 17.6936C27.1635 17.0111 29.5 17.1126 29.8824 19.0587"
        stroke="#7119E1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.1177 22.7302C27.2655 21.3948 30.2145 18.9896 31.5 18.6126C33.1069 18.1412 34 18.2525 35 20.1126"
        stroke="#7119E1"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconAbilityMotor;
