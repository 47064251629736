import * as React from 'react';

function SvgIconStatusWarning(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={21} height={18} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path d="M10.21 6.767v4.866m0 1.217V14.472m0-12.572l8.922 14.6H1.29l8.92-14.6z" strokeWidth={1.25} />
    </svg>
  );
}

export default SvgIconStatusWarning;
