import { Box } from 'grommet';
import React, { ComponentType, useMemo } from 'react';

import { ButtonProps } from '../Button/Button';
import { Ellipsis } from './Ellipsis';
import { NextPageNavigation, PreviousPageNavigation } from './PageNavigation';
import { PageNode } from './PageNode';
import { PageNodeBaseProps as PageNodeBasePropsType } from './types';

export interface PageNodeBaseProps extends PageNodeBasePropsType {
  /**
   * The current pagination page
   */
  current: number;
  /**
   * Context based page number
   */
  page: number;
  /**
   * Total number of pages
   */
  total: number;
  /**
   * Optional params
   */
  params?: Record<string, string>;
}

export type PaginationClickHandler = (params: PageNodeBaseProps) => any;

export interface PageNodeProps extends PageNodeBaseProps {
  /**
   * The current pagination page
   */
  current: number;
  /**
   * Context based page number
   */
  page: number;
  /**
   * Total number of pages
   */
  total: number;
  /**
   * Optional params
   */
  params?: Record<string, string>;
  /**
   * Click handler function
   */
  onClick?: PaginationClickHandler;
  /**
   * Addittional page node render props
   */
  btnProps?: ButtonProps | ((ctx: PageNodeProps) => ButtonProps);
}

export interface PaginationProps {
  /**
   * The current page number for pagination. Starts at `1`.
   */
  page: number;
  /**
   * Total number of pages
   */
  total: number;
  /**
   * Additional params object passed to click handlers
   */
  params?: Record<string, any>;
  /**
   * Click handler passed to page node and next/previous navigation components.
   * The handler function will be passed the `current` page number, `pageSize`,
   * `total`, additional passed `params` and a context `page`.
   */
  onClick?: PaginationClickHandler;
  /**
   * The number of page node elements to display before (previous pages)
   * and after (next pages) the active page node.
   */
  pagePad?: number;
  /**
   * Add additional props to button components (page node, previous and next buttons).
   * Also takes a function that gets passed page node context params and returns button props.
   */
  btnProps?: ButtonProps | ((ctx: PageNodeProps) => ButtonProps);
  /**
   * Custom component for rendering numerical page nodes.
   * The `page` context passed to this component is the number of the page being rendered.
   *
   */
  pageComponent?: ComponentType<PageNodeProps>;
  /**
   * Custom component for rendering omitted page numbers component.
   * Rendered between the first and active page, and the active and last
   * page when there exists more pages between them than the `pagePad` size.
   */
  ellipsisComponent?: ComponentType<PageNodeBaseProps>;
  /**
   * Custom component for rendering the next page component.
   * The `page` context passed to this component is the active page number - 1.
   */
  previousComponent?: ComponentType<PageNodeProps>;
  /**
   * Custom component for rendering the next page component.
   * The `page` context passed to this component is the active page number + 1.
   */
  nextComponent?: ComponentType<PageNodeProps>;
}

/**
 * The `Pagination` component is intended to be a controlled
 * component and simply provides the logic and UI for displaying pagination controls.
 * First, last and active page nodes are always displayed in the UI. The number of
 * pages before and after the active page is defined by the `pagePad` prop. If there
 * are additional pages between those covered by first, active, last and padded pages,
 * `ellipsisComponent`s will be rendered in either/both places before/after active/pad pages.
 * Page nodes and navigation are rendered as [`Button`s](#button) by default. You can customize
 * this render using the `btnProps` prop to pass additional props to the rendered buttons.
 * You can additionally fully customize components using the `pageComponent`, `ellipsisComponent`,
 * `previousComponent` and `nextComponent` props.
 * It should be noted that this component assumes a start page of `1`, **not** `0`.
 */
export const Pagination: React.FC<PaginationProps> = ({
  page,
  total,
  params,
  onClick = () => undefined,

  pagePad = 2,

  pageComponent: PageComponent = PageNode,
  previousComponent: PreviousComponent = PreviousPageNavigation,
  nextComponent: NextComponent = NextPageNavigation,
  ellipsisComponent: EllipsisComponent = Ellipsis,
  btnProps,
}: PaginationProps) => {
  const baseNodeProps = useMemo(
    () => ({
      current: page,
      total,
      params,
      btnProps,
    }),
    [page, total, params, btnProps],
  );
  const nodeProps = useMemo(
    () => ({
      ...baseNodeProps,
      onClick,
    }),
    [baseNodeProps, onClick],
  );
  // Populate page nodes
  const pageNodes = useMemo(() => {
    const nodes: React.ReactElement[] = [];

    if (baseNodeProps.total <= 1 + pagePad * 2) {
      // Show all pages if there are <= the aggregate nomber with pad
      Array.from({ length: nodeProps.total }).forEach((_, i) => {
        const pageNum = i + 1;
        nodes.push(<PageNode key={`page-${pageNum}`} {...nodeProps} page={pageNum} />);
      });
    } else {
      // Add page nodes conditionally:
      const firstPage = 1;
      const activePage = nodeProps.current;
      const lastPage = nodeProps.total;
      // conditions
      const renderFirstPage = activePage !== firstPage;
      const renderLastPage = activePage !== lastPage;
      const renderPreviousEllipsis = activePage - pagePad > firstPage + 1;
      const renderNextEllipsis = activePage + pagePad < lastPage - 1;

      // Add the first page if it's not the current page
      if (renderFirstPage) {
        nodes.push(<PageComponent key="first-page" {...nodeProps} page={firstPage} />);
      }

      // Render ellipsis
      if (renderPreviousEllipsis) {
        nodes.push(<EllipsisComponent key="previous-ellipsis" {...baseNodeProps} page={-1} />);
      }

      // Render Previous Pages
      for (let i = activePage - pagePad; i < activePage; i += 1) {
        // If this is greater than the first page
        if (i > 1) {
          nodes.push(
            <PageComponent key={`previous-page-num-${i}`} {...nodeProps} page={i}>
              toot
            </PageComponent>,
          );
        }
      }

      // Render Active Page
      nodes.push(<PageComponent key={`active-page-${activePage}`} {...nodeProps} page={activePage} />);

      // Render Next Pages
      for (let i = activePage + 1; i <= activePage + pagePad; i += 1) {
        // If this is less than the last page
        if (i < lastPage) {
          nodes.push(<PageComponent key={`next-page-num-${i}`} {...nodeProps} page={i} />);
        }
      }

      // Ellipsis
      if (renderNextEllipsis) {
        nodes.push(<EllipsisComponent key="next-ellipsis" {...baseNodeProps} page={-1} />);
      }

      // Last Page
      if (renderLastPage) {
        nodes.push(<PageComponent key={`last-page-${lastPage}`} {...nodeProps} page={lastPage} />);
      }
    }

    return nodes;
  }, [PageComponent, EllipsisComponent, baseNodeProps, nodeProps, pagePad]);

  return (
    <Box data-cy="pagination" direction="row" align="center">
      <PreviousComponent {...baseNodeProps} onClick={onClick} page={page - 1} />
      <Box data-cy="page-nodes" className="page-nodes" direction="row" margin={{ horizontal: 'small' }} gap="8px">
        {pageNodes}
      </Box>
      <NextComponent {...baseNodeProps} onClick={onClick} page={page + 1} />
    </Box>
  );
};
