import { AccordionPanel as GrommetAccordionPanel } from 'grommet';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { palette } from '../Theme/palette';

interface AccordionPanelProps {
  // active is used by styled-components, but not by the component itself
  // eslint-disable-next-line react/no-unused-prop-types
  active: boolean;
  heading: JSX.Element;
  className?: string;
  'data-cy'?: string;
}

const AccordionPanelW: React.FC<PropsWithChildren<AccordionPanelProps>> = ({
  heading,
  children,
  className,
  'data-cy': dataCy,
}) => (
  <GrommetAccordionPanel data-cy={dataCy} className={className} label={heading}>
    {children}
  </GrommetAccordionPanel>
);

const base = {
  height: '80px',
  border: `1px solid ${palette['gray-100']}`,
  boxShadow: `0px 0px 0px ${palette['gray-100']}`,
  background: palette.white,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '& svg': {
    marginRight: '10px',
    fill: palette['gray-800'],
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '& h2': {
    color: palette['gray-800'],
    padding: '26px 0px 27px 48px',
  },
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '& div:focus': {
    outline: 'none',
  },
};

export const AccordionPanel = styled(AccordionPanelW)(({ theme, active }) =>
  active ? { ...base, borderBottomStyle: 'none' } : { ...base, boxShadow: `0px 0px 8px ${theme.palette['gray-100']}` },
);
