export const remToPx = (remStr: string) => {
  const remVal = parseFloat(remStr);
  if (Number.isNaN(remVal) || !/^[0-9.]+rem$/.test(remStr)) {
    throw new Error('Invalid rem value');
  }

  const baseFontSize = 16; // Using default 1rem or 16px standard for now
  const pxVal = baseFontSize * remVal;
  return `${pxVal}px`;
};
