import * as React from 'react';

function IconLabels(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="28" cy="28" r="28" fill="#E7DEFC" />
      <path
        d="M32.2168 43.4942L43.7329 31.7845L27.7097 16L15 15L16 27.4193L32.2168 43.4942Z"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6802 20.3548C18.6802 21.3792 19.5107 22.2097 20.5351 22.2097C21.5595 22.2097 22.3899 21.3792 22.3899 20.3548C22.3899 19.3304 21.5595 18.5 20.5351 18.5C19.5107 18.5 18.6802 19.3304 18.6802 20.3548Z"
        fill="#7119E1"
      />
    </svg>
  );
}

export default IconLabels;
