import * as React from 'react';

import { IconProps } from '../types';

function SvgIconCertificate({ color = 'currentColor', ...rest }: IconProps) {
  return (
    <svg
      aria-hidden
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.375 6H6H16H16.625V6.625V8.55185C18.3986 8.8494 19.75 10.3919 19.75 12.25C19.75 13.3606 19.2672 14.3585 18.5 15.0451V19.75V20.9178L17.5283 20.2701L16 19.2512L14.4717 20.2701L13.5 20.9178V19.75V18.5H6H5.375V17.875V6.625V6ZM13.5 17.25V15.0451C12.7328 14.3585 12.25 13.3606 12.25 12.25C12.25 10.3919 13.6014 8.8494 15.375 8.55185V7.25H6.625V17.25H13.5ZM14.75 15.7866V18.5822L15.6533 17.9799L16 17.7488L16.3467 17.9799L17.25 18.5822V15.7866C16.859 15.9248 16.4383 16 16 16C15.5617 16 15.141 15.9248 14.75 15.7866ZM16 9.75C14.6193 9.75 13.5 10.8693 13.5 12.25C13.5 13.6307 14.6193 14.75 16 14.75C17.3807 14.75 18.5 13.6307 18.5 12.25C18.5 10.8693 17.3807 9.75 16 9.75Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgIconCertificate;
