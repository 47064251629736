import { MarginType } from 'grommet/utils';
import React from 'react';
import styled, { CSSObject } from 'styled-components';

import { textVariantStyle } from '../Text/Text';
import { theme } from '../Theme';

export type OrderedListNumbersVariant = 'text-reg';
export const orderedListNumbersVariantStyle: Record<OrderedListNumbersVariant, CSSObject> = {
  // styles list numbers to match reg Text variant
  'text-reg': {
    ...textVariantStyle.reg,
  },
};

export interface OrderedListProps {
  /**
   * Set as aria-labelledby prop on the ol tag
   */
  ariaLabelledBy?: string;
  /**
   * Array of React Components that will render in individual li tags
   * E.g. an array of Text components
   */
  items: React.ReactNode[];
  /**
   * Style variants for the ordered list numbers.
   *  - undefined: uses defaults in CustomTheme.orderedList, use when passing in strings
   *  - text-reg: used to match list numbers style to style of of Text (reg variant) components
   */
  numbersStyleVariant?: OrderedListNumbersVariant;
  /**
   * Change the space between list items
   */
  itemSpacing?: MarginType;
}

const StyledOrderedList = styled('ol')(({ style }) => ({ ...style }));

export const OrderedList: React.FC<OrderedListProps> = ({
  items,
  ariaLabelledBy,
  numbersStyleVariant,
  itemSpacing,
}: OrderedListProps) => {
  const style = {
    ...theme.orderedList,
    li: {
      ...theme.orderedListItems,
      ...(itemSpacing ? { marginBottom: itemSpacing } : {}),
      '::before': {
        ...theme.orderedListNumbers,
        ...(numbersStyleVariant ? orderedListNumbersVariantStyle[numbersStyleVariant] : {}),
      },
    },
  };

  return (
    <StyledOrderedList aria-labelledby={ariaLabelledBy} style={style}>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </StyledOrderedList>
  );
};
