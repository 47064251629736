import { palette as basePalette } from './palette';

export const springTimePalette = {
  ...basePalette,

  'shadow-rgba': 'rgba(43, 50, 59, 0.8)',

  'gray-100': '#E9EBF0',
  'gray-50': '#F3F5F9',

  'brand-900': '#003B48',
  'brand-800': '#034D5E',
  'brand-700': '#056B82',
  'brand-600': '#09819B',
  'brand-500': '#0AADC3',
  'brand-400': '#28CBE1',
  'brand-300': '#6DDEED',
  'brand-200': '#95E9F4',
  'brand-100': '#C1F2F9',
  'brand-50': '#DFF7FB',
  'brand-25': '#F5FDFF',

  'green-900': '#16331E',
  'green-800': '#204A2C',
  'green-700': '#32673A',
  'green-600': '#3D8448',
  'green-500': '#51A75A',
  'green-400': '#6CC175',
  'green-300': '#8FD796',
  'green-200': '#B1E9B7',
  'green-100': '#CEF1D2',
  'green-50': '#E7F9E9',
  'green-25': '#F7FDF7',

  'blue-900': '#242865',
  'blue-800': '#36398D',
  'blue-700': '#4C53B5',
  'blue-600': '#606FCE',
  'blue-500': '#808FF3',
  'blue-400': '#96A3FD',
  'blue-300': '#AEC1FF',
  'blue-200': '#C9D7FF',
  'blue-100': '#DAE4FF',
  'blue-50': '#EBF1FF',
  'blue-25': '#FAFBFF',

  'red-900': '#58100B',
  'red-800': '#89140C',
  'red-700': '#B2251B',
  'red-600': '#CE453C',
  'red-500': '#EF675E',
  'red-400': '#FF8881',
  'red-300': '#FFADA7',
  'red-200': '#FFCCC9',
  'red-100': '#FFE6E5',
  'red-50': '#FFF3F2',

  'yellow-400': '#FCC867',
};

export type STThemePalette = typeof springTimePalette;
