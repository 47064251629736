import * as React from 'react';

function SvgIconShare(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M14.5 11C14.5 12.9526 14.5 14.0474 14.5 16H5.5V7H10.5M12 5H16.5M16.5 5V9.5M16.5 5L10 11.5"
        strokeWidth="1.25"
        stroke="currentColor"
      />
    </svg>
  );
}

export default SvgIconShare;
