import * as React from 'react';

function SvgIconPrint(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        d="M7.636 17.09H4V8.365h16v8.727h-3.636m-10.91-2.182h13.091m-10.909 0V20h8.728v-5.09H7.636zm8.728-6.545V4H7.636v4.364h8.728z"
        strokeWidth={1.125}
      />
    </svg>
  );
}

export default SvgIconPrint;
