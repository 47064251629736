export interface ThemeBreakpoints {
  xsmall: number;
  small: number;
  medium: number;
  large: number;
  xlarge: number;
}

export const breakpoints: ThemeBreakpoints = {
  xsmall: 480,
  small: 768,
  medium: 1280,
  large: 1440,
  xlarge: 1600,
};
