import * as React from 'react';

function SvgIconSolutionStarPlan(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M9.426 7.023a2.242 2.242 0 0 0-2.25 2.235v12.235a3.186 3.186 0 0 0 3.196 3.177 3.193 3.193 0 0 0 3.097-2.39c.173-.679.761-1.375 1.632-1.375h6.033V9.258a2.242 2.242 0 0 0-2.25-2.235H9.427ZM22.31 20.905V9.258a3.419 3.419 0 0 0-3.425-3.412h-9.46A3.419 3.419 0 0 0 6 9.258v12.235c0 2.407 1.96 4.353 4.372 4.353h11.35a4.37 4.37 0 0 0 4.237-3.275c.119-.465-.029-.9-.31-1.202a1.49 1.49 0 0 0-1.09-.464H22.31Zm-8.95 3.765h8.362a3.193 3.193 0 0 0 3.097-2.39.1.1 0 0 0 .002-.056.134.134 0 0 0-.033-.053.315.315 0 0 0-.228-.09H15.1c-.174 0-.408.16-.493.49a4.347 4.347 0 0 1-1.247 2.099Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M10.3 8.834a1.096 1.096 0 0 1 1.988 0l.314.66.69.104c.93.14 1.24 1.28.617 1.91l-.513.52.122.741c.143.869-.756 1.645-1.611 1.178l-.613-.335-.612.335c-.856.467-1.755-.31-1.612-1.178l.122-.74-.513-.52c-.622-.63-.313-1.77.617-1.91l.69-.105.314-.66Zm.926.505Zm.068.144-.259.545a1.106 1.106 0 0 1-.832.623l-.624.095.467.473c.257.26.369.628.31.984l-.105.639.52-.285a1.087 1.087 0 0 1 1.045 0l.522.284-.106-.638a1.138 1.138 0 0 1 .31-.984l.467-.473-.624-.095a1.106 1.106 0 0 1-.832-.623l-.259-.545Zm1.177 3.431Zm-2.354 0ZM9.137 18.199c0 .325.264.588.588.588h8.628a.588.588 0 0 0 0-1.176H9.725a.588.588 0 0 0-.588.588ZM9.137 15.846c0-.325.264-.588.588-.588h8.628a.588.588 0 0 1 0 1.176H9.725a.588.588 0 0 1-.588-.588Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconSolutionStarPlan;
