import * as React from 'react';

import { IconProps } from '../types';

function SvgIconFilter({ a11yTitle, color = 'currentColor', ...rest }: IconProps) {
  return (
    <svg
      aria-label={a11yTitle}
      width="24"
      height="24"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" fill={color} stroke={color} />
    </svg>
  );
}

export default SvgIconFilter;
