import { Anchor } from 'grommet';
import React from 'react';
import styled from 'styled-components';

import { palette } from '../Theme/palette';

function ExternalIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        stroke="none"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1893 4.75H13.8636V3.25H20.75V10.1364H19.25V5.81066L11.6667 13.394L10.606 12.3333L18.1893 4.75ZM4.25 5.97727H11.8182V7.47727H5.75V18.25H16.5227V12.1818H18.0227V19.75H4.25V5.97727Z"
        fill={palette['purple-600']}
      />
    </svg>
  );
}

type LinkTarget = '_blank' | '_self' | '_parent' | '_top';

const LinkWrapped: ({
  href,
  children,
  datacy,
}: {
  href?: string;
  children: React.ReactNode;
  datacy?: string;
  className?: string;
  fontSize?: string;
  fontWeight?: number;
  underline?: boolean;
  icon?: boolean;
  target?: LinkTarget;
}) => JSX.Element = ({ href, children, datacy = '', className = '', icon = false, target }) => (
  <Anchor
    gap="xxsmall"
    target={target}
    rel="noreferrer"
    data-cy={datacy}
    href={href}
    label={children}
    className={className}
    icon={icon ? <ExternalIcon /> : <></>}
    reverse
  />
);

export const Link = styled(LinkWrapped)(({ underline = true, fontSize = '1rem', fontWeight = 400 }) => ({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '& :hover': {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    '& svg': {
      opacity: 0.75,
    },
  },

  // underline style
  ...(underline
    ? {
        textDecoration: 'underline',
      }
    : { textDecoration: 'none' }),
  fontWeight,
  fontSize,
}));
