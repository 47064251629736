import * as React from 'react';

function IconHeadings(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="28" cy="28" r="28" fill="#E7DEFC" />
      <path
        d="M12 37.8064V19H17.5139V26.2901H24.1942V19H29.39V37.8064H24.1942V30.8332H17.5139V37.8064H12Z"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.6001 26.7127V22.4866L37.4602 19H42.4259V37.8064H37.4602V23.5431L32.6001 26.7127Z"
        stroke="#7119E1"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconHeadings;
