import React from 'react';

import {
  IconAccessibility,
  IconAccessibilityStatement,
  IconBarChart,
  IconCaretDown,
  IconCompliance,
  IconConfigure,
  IconFlashingMedia,
  IconHeadings,
  IconInteractive,
  IconLabels,
  IconLegal,
  IconLinks,
  IconNavigation,
  IconNewTab,
  IconShare,
} from './icons';
import { CustomIcon } from './icons/CustomIcon';
import { GrommetIcon } from './icons/GrommetIcon';
import IconAbilityAudio from './icons/IconAbilityAudio';
import IconAbilityCognitive from './icons/IconAbilityCognitive';
import IconAbilityMotor from './icons/IconAbilityMotor';
import IconAbilityVisual from './icons/IconAbilityVisual';
import IconArrowRight from './icons/IconArrowRight';
import IconAudioVideo from './icons/IconAudioVideo';
import IconCertificate from './icons/IconCertificate';
import IconCircle from './icons/IconCircle';
import IconCircleCheck from './icons/IconCircleCheck';
import IconContent from './icons/IconContent';
import IconContrast from './icons/IconContrast';
import SvgIconCopy from './icons/IconCopy';
import IconFilter from './icons/IconFilter';
import IconForms from './icons/IconForms';
import IconInfoCircle from './icons/IconInfoCircle';
import IconLayout from './icons/IconLayout';
import IconLegalDocuments from './icons/IconLegalDocuments';
import IconRuler from './icons/IconRuler';
import IconScroll from './icons/IconScroll';
import IconSectionCompliance from './icons/IconSectionCompliance';
import IconSectionDescription from './icons/IconSectionDescription';
import IconSectionImpact from './icons/IconSectionImpact';
import IconSectionResolution from './icons/IconSectionResolution';
import IconSolutionAccessbilityStatement from './icons/IconSolutionAccessibilityStatement';
import IconSolutionAutomatedFixes from './icons/IconSolutionAutomatedFixes';
import IconSolutionAutomatedTesting from './icons/IconSolutionAutomatedTesting';
import IconSolutionHelpDesk from './icons/IconSolutionHelpDesk';
import IconSolutionManualAudit from './icons/IconSolutionManualAudit';
import IconSolutionManualFix from './icons/IconSolutionManualFix';
import IconSolutionOnlineTraining from './icons/IconSolutionOnlineTraining';
import IconSolutionReportingGuidance from './icons/IconSolutionReportingGuidance';
import IconSolutionStarPlan from './icons/IconSolutionStarPlan';
import IconSolutionVisualToolkit from './icons/IconSolutionVisualToolkit';
import IconStatusGoodFilled from './icons/IconStatusGoodFilled';
import IconTooltip from './icons/IconTooltip';
import IconTrustedByCertificate from './icons/IconTrustedByCertificate';
import IconUpdate from './icons/IconUpdate';
import IconVerticalDashes from './icons/IconVerticalDashes';
import IconVideo from './icons/IconVideo';
import { BaseIconProps } from './types';

// NOTE: As icon count grows we might want to export icons individually to take advantage of tree shaking :shrug:
export const IconTypeValues = [
  'ability-audio',
  'ability-cognitive',
  'ability-motor',
  'ability-visual',
  'accessibility',
  'accessibility-statement',
  'add-circle',
  'arrow-right',
  'article',
  'ascending',
  'audio-video',
  'bar-chart',
  'calendar',
  'caret-down',
  'certificate',
  'circle',
  'circle-check',
  'close',
  'code',
  'compliance',
  'configure',
  'content',
  'contrast',
  'copy',
  'currency',
  'cycle',
  'descending',
  'dots-horizontal',
  'down',
  'download',
  'filter',
  'flashing-media',
  'forms',
  'headings',
  'hide',
  'info-circle',
  'interactive',
  'labels',
  'layout',
  'legal',
  'legal-documents',
  'links',
  'mail',
  'menu',
  'more',
  'navigation',
  'new-tab',
  'next',
  'pause',
  'performance',
  'play',
  'power-reset',
  'previous',
  'print',
  'ruler',
  'scan',
  'scroll',
  'search',
  'section-compliance',
  'section-description',
  'section-impact',
  'section-resolution',
  'share',
  'show',
  'solution-accessibility-statement',
  'solution-automated-fixes',
  'solution-automatic-testing',
  'solution-help-desk',
  'solution-manual-audit',
  'solution-manual-fix',
  'solution-online-training',
  'solution-reporting-guidance',
  'solution-star-plan',
  'solution-visual-toolkit',
  'star',
  'status-checkmark',
  'status-good',
  'status-good-filled',
  'status-warning',
  'tooltip',
  'trashcan',
  'trusted-by-certificate',
  'unsorted',
  'up',
  'update',
  'upload',
  'user',
  'vertical-dashes',
  'video',
] as const;

export type IconType = (typeof IconTypeValues)[number];

export interface IconProps extends Omit<BaseIconProps, 'type'> {
  /**
   * The icon to render from the available icon types
   */
  type: IconType;
}

const iconMap = {
  'ability-audio': IconAbilityAudio,
  'ability-cognitive': IconAbilityCognitive,
  'ability-motor': IconAbilityMotor,
  'ability-visual': IconAbilityVisual,
  'accessibility-statement': IconAccessibilityStatement,
  'add-circle': GrommetIcon,
  'arrow-right': IconArrowRight,
  'bar-chart': IconBarChart,
  'caret-down': IconCaretDown,
  'info-circle': IconInfoCircle,
  'legal-documents': IconLegalDocuments,
  'new-tab': IconNewTab,
  'power-reset': GrommetIcon,
  'section-compliance': IconSectionCompliance,
  'section-description': IconSectionDescription,
  'section-impact': IconSectionImpact,
  'section-resolution': IconSectionResolution,
  'solution-accessibility-statement': IconSolutionAccessbilityStatement,
  'solution-automated-fixes': IconSolutionAutomatedFixes,
  'solution-automatic-testing': IconSolutionAutomatedTesting,
  'solution-help-desk': IconSolutionHelpDesk,
  'solution-manual-audit': IconSolutionManualAudit,
  'solution-manual-fix': IconSolutionManualFix,
  'solution-online-training': IconSolutionOnlineTraining,
  'solution-reporting-guidance': IconSolutionReportingGuidance,
  'solution-star-plan': IconSolutionStarPlan,
  'solution-visual-toolkit': IconSolutionVisualToolkit,
  'status-good': GrommetIcon,
  'trusted-by-certificate': IconTrustedByCertificate,
  accessibility: IconAccessibility,
  ascending: GrommetIcon,
  certificate: IconCertificate,
  circle: IconCircle,
  'circle-check': IconCircleCheck,
  close: GrommetIcon,
  compliance: IconCompliance,
  configure: IconConfigure,
  currency: CustomIcon,
  cycle: GrommetIcon,
  descending: GrommetIcon,
  down: GrommetIcon,
  legal: IconLegal,
  menu: GrommetIcon,
  more: GrommetIcon,
  next: GrommetIcon,
  pause: GrommetIcon,
  performance: GrommetIcon,
  play: GrommetIcon,
  previous: GrommetIcon,
  ruler: IconRuler,
  search: GrommetIcon,
  share: IconShare,
  unsorted: GrommetIcon,
  up: GrommetIcon,
  update: IconUpdate,
  upload: GrommetIcon,
  'vertical-dashes': IconVerticalDashes,

  // Custom Icons
  article: CustomIcon,
  calendar: CustomIcon,
  code: CustomIcon,
  copy: SvgIconCopy,
  'dots-horizontal': CustomIcon,
  download: CustomIcon,
  hide: CustomIcon,
  mail: CustomIcon,
  print: CustomIcon,
  scan: CustomIcon,
  scroll: IconScroll,
  show: CustomIcon,
  star: CustomIcon,
  'status-checkmark': CustomIcon,
  'status-warning': CustomIcon,
  trashcan: CustomIcon,
  user: CustomIcon,

  // Custom Styled Icons
  filter: IconFilter,
  'status-good-filled': IconStatusGoodFilled,
  tooltip: IconTooltip,
  video: IconVideo,

  'audio-video': IconAudioVideo,
  content: IconContent,
  contrast: IconContrast,
  'flashing-media': IconFlashingMedia,
  forms: IconForms,
  headings: IconHeadings,
  interactive: IconInteractive,
  labels: IconLabels,
  layout: IconLayout,
  links: IconLinks,
  navigation: IconNavigation,
};

export type IconTitleMap<T extends string, P = string> = Record<T, P>;

const customIconTypeToTitleMap: IconTitleMap<string> = {
  contrast: 'Contrast & Color',
  content: 'Content',
  audioVideo: 'Audio-Video',
  flashingMedia: 'Flashing Media',
  forms: 'Forms',
  layout: 'Layout',
  headings: 'Headings',
  links: 'Links',
  interactive: 'Interactive',
  navigation: 'Navigation',
  labels: 'Labels',
  next: 'Next',
  previous: 'Previous',
  close: 'Close',
};

/**
 * SVG icons component
 */
export const Icon: React.FC<IconProps & JSX.IntrinsicElements['svg']> = ({
  type: iconType,
  color = 'currentColor',
  size,
  a11yTitle,
  ...rest
}: IconProps) => {
  const IconComponent = iconMap[iconType];

  const title = customIconTypeToTitleMap[iconType] || a11yTitle;

  const sizeProps = size ? { width: size, height: size } : {};
  const titleProp = title ? { 'aria-label': title } : {};

  return <IconComponent {...rest} type={iconType} color={color} {...sizeProps} {...titleProp} size={size} />;
};
