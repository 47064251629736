/**
 * Theme palette colors
 * These are the colors used throughout the site
 */
export const palette = {
  'brand-900': '#250353',
  'brand-800': '#360279',
  'brand-700': '#450498',
  'brand-600': '#5B0EBF',
  'brand-500': '#7119E1',
  'brand-400': '#8753F4',
  'brand-300': '#A281FF',
  'brand-200': '#B9A1FF',
  'brand-100': '#D2C7FF',
  'brand-50': '#E7E2FF',
  'brand-25': '#F5F1FD',
  black: '#000000',
  'gray-900': '#14181F',
  'gray-800': '#2B323B',
  'gray-700': '#454B54',
  'gray-600': '#505863',
  'gray-500': '#69707D',
  'gray-400': '#888E99',
  'gray-300': '#B0B6C0',
  'gray-200': '#D0D3DB',
  'gray-100': '#E4E6EA',
  'gray-50': '#F1F2F6',
  'gray-25': '#FBFCFE',
  white: '#FFFFFF',
  'shadow-rgba': 'rgba(43, 52, 54, 0.08)',

  'purple-900': '#250353',
  'purple-800': '#360279',
  'purple-700': '#450498',
  'purple-600': '#5B0EBF',
  'purple-500': '#7119E1',
  'purple-400': '#8753F4',
  'purple-300': '#A281FF',
  'purple-200': '#B9A1FF',
  'purple-100': '#D2C7FF',
  'purple-50': '#E7E2FF',
  'purple-25': '#F5F1FD',

  'green-900': '#00393D',
  'green-800': '#015959',
  'green-700': '#03857D',
  'green-600': '#23AC9C',
  'green-500': '#22C7B3',
  'green-400': '#4CDAC9',
  'green-300': '#7EE7D3',
  'green-200': '#B0ECE1',
  'green-100': '#CEF3EC',
  'green-50': '#E7F9F6',
  'green-25': '#F7FDFC',

  'red-900': '#660600',
  'red-800': '#990A00',
  'red-700': '#B81F14',
  'red-600': '#D34137',
  'red-500': '#F96B61',
  'red-400': '#FF8C85',
  'red-300': '#FFA9A3',
  'red-200': '#FBC9C5',
  'red-100': '#FDE4E3',
  'red-50': '#FEF2F1',
  'red-25': '#FFFAFA',

  'yellow-900': '#472207',
  'yellow-800': '#7E3D00',
  'yellow-700': '#AF621B',
  'yellow-600': '#CE820F',
  'yellow-500': '#EA9D08',
  'yellow-400': '#FBB346',
  'yellow-300': '#FFCD70',
  'yellow-200': '#FFDB92',
  'yellow-100': '#FFEBBD',
  'yellow-50': '#FFF6E2',
  'yellow-25': '#FFFCF5',
};

export type ThemePalette = typeof palette;

export const radiusPalette = {
  modal: '2rem',
  button: '0.25rem',
};
export type RadiusPalette = typeof radiusPalette;

export const paddingPalette = {
  modal: '2.5rem',
};
export type PaddingPalette = typeof paddingPalette;
