import * as React from 'react';

function IconVerticalDashes(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path stroke="#69707D" d="M16.5 2.333v4m0 4v4" />
    </svg>
  );
}

export default IconVerticalDashes;
