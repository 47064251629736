import * as React from 'react';

function IconLayout(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="28" cy="28" r="28" fill="#E7DEFC" />
      <mask id="path-2-inside-1_403_2152" fill="white">
        <rect x="15" y="22" width="7" height="19" rx="0.5" />
      </mask>
      <rect
        x="15"
        y="22"
        width="7"
        height="19"
        rx="0.5"
        stroke="#7119E1"
        strokeWidth="3"
        strokeLinejoin="round"
        mask="url(#path-2-inside-1_403_2152)"
      />
      <mask id="path-3-inside-2_403_2152" fill="white">
        <rect x="24" y="22" width="18" height="19" rx="0.5" />
      </mask>
      <rect
        x="24"
        y="22"
        width="18"
        height="19"
        rx="0.5"
        stroke="#7119E1"
        strokeWidth="3"
        strokeLinejoin="round"
        mask="url(#path-3-inside-2_403_2152)"
      />
      <mask id="path-4-inside-3_403_2152" fill="white">
        <rect x="15" y="15" width="27" height="5" rx="0.5" />
      </mask>
      <rect
        x="15"
        y="15"
        width="27"
        height="5"
        rx="0.5"
        stroke="#7119E1"
        strokeWidth="3"
        strokeLinejoin="round"
        mask="url(#path-4-inside-3_403_2152)"
      />
    </svg>
  );
}

export default IconLayout;
