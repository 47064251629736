import { base as GrBase, ColumnSizeType } from 'grommet';
import { deepMerge as GrdeepMerge, MarginType as grMarginType, PadType as GrPadType } from 'grommet/utils';

export const deepMerge = GrdeepMerge;
export const base = GrBase;

export type MarginType = grMarginType;
export type PadType = GrPadType;
export type ColumnConfig<TRowType, TSortFields extends string = never> = {
  align?: 'center' | 'start' | 'end';
  aggregate?: 'avg' | 'max' | 'min' | 'sum';
  footer?: React.ReactNode | { aggregate?: boolean };
  header?: (string | React.ReactNode) & { aggregate?: boolean };
  pin?: boolean;
  plain?: boolean;
  primary?: boolean;
  render?: (datum: TRowType) => React.ReactNode;
  search?: boolean;
  show?: number | { page?: number };
  size?: ColumnSizeType | string;
  units?: string;
  verticalAlign?: 'middle' | 'top' | 'bottom';
} & ({ sortable: true; property: TSortFields } | { sortable?: false; property: string });
