import * as React from 'react';

function SvgIconSolutionManualFix(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M11.081 8.078C11.081 6.898 12.085 6 13.25 6h1.58C15.995 6 17 6.898 17 8.078c0 .496.53.811.965.574l.084-.046c1.018-.554 2.335-.237 2.942.755l.79 1.29c.622 1.016.238 2.296-.798 2.86a.601.601 0 0 0 0 1.056 2.07 2.07 0 0 1 1.09 1.634l-1.172.103a.895.895 0 0 0-.48-.704c-1.238-.673-1.238-2.449 0-3.122.475-.259.609-.801.357-1.212l-.79-1.29c-.265-.433-.882-.605-1.376-.336l-.084.045a1.83 1.83 0 0 1-2.705-1.607c0-.466-.41-.902-.992-.902h-1.58c-.581 0-.993.436-.993.902a1.83 1.83 0 0 1-2.704 1.607l.281-.516-.28.516-.085-.045c-.494-.27-1.111-.097-1.376.336l-.79 1.29c-.252.41-.118.953.358 1.212 1.237.673 1.237 2.45 0 3.122-.476.26-.61.801-.358 1.212l.79 1.29c.265.434.882.606 1.376.337l.281.516-.281-.516.084-.046A1.83 1.83 0 0 1 12.257 20c0 .466.412.902.993.902h1.58c.303 0 .568-.123.747-.309l.847.816c-.399.415-.969.67-1.594.67h-1.58c-1.165 0-2.169-.899-2.169-2.079 0-.495-.53-.81-.965-.574l-.282-.516.282.516-.085.046c-1.017.554-2.334.238-2.941-.755l-.79-1.29c-.622-1.015-.238-2.296.798-2.86a.601.601 0 0 0 0-1.056c-1.036-.564-1.42-1.844-.799-2.86l.79-1.29c.608-.992 1.925-1.309 2.942-.755l-.28.517.28-.517.085.046a.653.653 0 0 0 .965-.574Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M14.04 11.833a2.206 2.206 0 1 0 .47 4.362l.248 1.15a3.382 3.382 0 1 1 2.61-2.706l-1.157-.208a2.206 2.206 0 0 0-2.171-2.598Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M16.612 13.954c-1.643-.548-3.205 1.015-2.658 2.657l2.651 7.953c.639 1.915 3.348 1.915 3.986 0l.847-2.541a.925.925 0 0 1 .585-.585l2.541-.847c1.916-.639 1.916-3.348 0-3.986l-7.952-2.651Zm-1.542 2.285a.925.925 0 0 1 1.17-1.17l7.952 2.652c.843.28.843 1.473 0 1.754l-2.54.847c-.628.209-1.12.701-1.33 1.329l-.847 2.54c-.28.843-1.473.843-1.754 0l-2.65-7.952Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconSolutionManualFix;
