import React from 'react';
import styled, { CSSObject } from 'styled-components';

export interface HorizontalRuleProps {
  /**
   * Changes to the default style of the horizontal rule component.
   */
  style?: CSSObject;
}

const StyledHorizontalRule = styled('hr')(({ theme: { horizontalRule }, style }) => ({ ...horizontalRule, ...style }));

export const HorizontalRule: React.FC<HorizontalRuleProps> = ({ style }) => <StyledHorizontalRule style={style} />;
