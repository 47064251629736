import { Button as GrButton, Stack as GrStack, TextInput as GrTextInput } from 'grommet';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import { fieldError, fieldId } from '../FormField/field-id-generator';
import { FormField } from '../FormField/FormField';
import { Icon } from '../Icon/Icon';
import { TextInputProps } from '../TextInput/TextInput';

export interface PasswordInputProps extends TextInputProps {
  /**
   * Icon displayed when password is hidden
   */
  showIcon?: JSX.Element;
  /**
   * Label for show icon
   */
  showLabel?: string;
  /**
   * Icon displayed when password is visible
   */
  hideIcon?: JSX.Element;
  /**
   * Label for for hide icon
   */
  hideLabel?: string;
  /**
   * Set initial state to show password value
   */
  initialShow?: boolean;
}

const StyledPasswordInput = styled(GrTextInput)(({ theme, 'aria-invalid': ariaInvalid }) => ({
  fontSize: '1rem',
  fontWeight: 400,
  padding: 10,
  paddingRight: 44,

  // placeholder
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&::placeholder': {
    color: theme.palette['gray-600'],
    fontStyle: 'italic',
  },

  // Invalid style
  ...(ariaInvalid
    ? {
        color: theme.palette['red-800'],
        borderColor: theme.palette['red-800'],

        // eslint-disable-next-line @typescript-eslint/naming-convention
        '&::placeholder': {
          color: theme.palette['red-600'],
          fontStyle: 'italic',
        },
      }
    : {}),
}));
const StyledIconButton = styled(GrButton)(({ theme }) => ({
  height: 44,
  width: 44,
  padding: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 'none',
  color: 'inherit',
  position: 'relative',
  zIndex: 10000,

  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&.invalid': {
    color: theme.palette['red-800'],
  },

  // eslint-disable-next-line @typescript-eslint/naming-convention
  '&:focus': {
    boxShadow: 'none',

    // eslint-disable-next-line @typescript-eslint/naming-convention
    '&:after': {
      boxSizing: 'content-box',
      display: 'block',
      content: '""',
      position: 'absolute',
      padding: 4,
      border: `3px solid ${theme.palette['purple-600']}`,
      width: '100%',
      height: '100%',
      left: -7,
      top: -7,
      borderRadius: 'inherit',
    },
  },
}));

/**
 * A text input with type `"password"` and visibility toggle button.
 */
export const PasswordInput = React.forwardRef<
  HTMLInputElement,
  PasswordInputProps &
    Omit<JSX.IntrinsicElements['input'], 'onSelect' | 'size' | 'placeholder' | 'enterKeyHint' | 'width'>
>(
  (
    {
      name,
      label,
      error,
      required,
      onBlur,
      onFocus,
      'aria-describedby': ariaDescribedBy,
      disabled,

      showIcon = <Icon type="show" />,
      showLabel = 'Show Password',
      hideIcon = <Icon type="hide" />,
      hideLabel = 'Hide Password',
      initialShow = false,
      ...rest
    }: PasswordInputProps &
      Omit<JSX.IntrinsicElements['input'], 'onSelect' | 'size' | 'placeholder' | 'enterKeyHint' | 'width'>,
    ref,
  ) => {
    const [showPassword, setShowPassword] = useState(initialShow);
    const inputType = useMemo(() => (showPassword ? 'text' : 'password'), [showPassword]);
    const iconType = useMemo(() => (showPassword ? 'hide' : 'show'), [showPassword]);
    const toggleTitle = useMemo(() => (showPassword ? hideLabel : showLabel), [showLabel, hideLabel, showPassword]);
    const toggleVisibility = useCallback(() => setShowPassword(!showPassword), [showPassword, setShowPassword]);
    const isInvalid = !!error;
    const describedBy = `${ariaDescribedBy || ''} ${error ? fieldError(name) : ''}`;

    return (
      <FormField name={name} label={label} required={required} error={error}>
        <GrStack anchor="right" style={{ zIndex: 1 }}>
          <StyledPasswordInput
            name={name}
            aria-invalid={isInvalid}
            aria-required={required}
            aria-describedby={describedBy}
            disabled={disabled}
            {...rest}
            type={inputType}
            id={fieldId(name)}
            ref={ref}
          />
          <StyledIconButton
            className={isInvalid ? 'invalid' : undefined}
            icon={<Icon type={iconType} />}
            onClick={toggleVisibility}
            a11yTitle={toggleTitle}
            disabled={disabled}
          />
        </GrStack>
      </FormField>
    );
  },
);
