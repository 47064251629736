import * as React from 'react';

function IconSectionImpact(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width="32"
      height="32"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="28" cy="28" r="28" fill="#E7DEFC" />
      <circle cx="25.5" cy="30.5" r="3.5" fill="#7119E1" />
      <circle cx="34.5" cy="22.5" r="5.5" fill="#7119E1" />
      <circle cx="18.5" cy="37.5" r="2.5" fill="#7119E1" />
    </svg>
  );
}

export default IconSectionImpact;
