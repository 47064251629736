export interface ThemeZIndex {
  [name: string]: number;
}

export const zIndex: ThemeZIndex = {
  header: 50,
  toast: 90,
  dropdown: 100,
  layer: 100,
};
