import * as React from 'react';

function SvgIconBarChart(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M4.25 18.75H19.25H4.25ZM18 6.25H15.5V16.25H18V6.25ZM8 8.75H5.5V16.25H8V8.75ZM13 16.25H10.5V11.25H13V16.25Z"
        strokeWidth="1.33929"
        stroke="currentColor"
      />
    </svg>
  );
}

export default SvgIconBarChart;
