import * as React from 'react';

function SvgIconConfigure(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M13.9996 14.375C16.5052 14.3347 18.3746 12.4796 18.3746 10C18.3746 8.11758 17.7515 8.74073 17.1246 9.375C16.5052 9.98702 15.2496 11.25 15.2496 11.25L12.7496 8.75C12.7496 8.75 14.0126 7.49444 14.6246 6.875C15.2589 6.24815 15.2589 5.625 13.9996 5.625C11.52 5.625 9.65777 7.49444 9.6246 10C9.65056 10.6102 9.6246 11.875 9.6246 11.875C8.44639 13.0606 6.91162 14.5954 5.8746 15.625C4.04225 17.4648 6.53484 19.9573 8.3746 18.125C9.40617 17.086 10.945 15.5471 12.1246 14.375C12.1246 14.375 13.3894 14.349 13.9996 14.375Z"
        strokeWidth="1.33929"
        stroke="currentColor"
      />
    </svg>
  );
}

export default SvgIconConfigure;
