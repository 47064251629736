import {
  Accessibility,
  AddCircle,
  Ascending,
  Close,
  Configure,
  Descending,
  Down,
  Menu,
  MoreVertical,
  Next,
  Pause,
  Performance,
  Play,
  PowerReset,
  Previous,
  Search,
  Share,
  StatusGood,
  Unsorted,
  Up,
  Update,
  Upload,
} from 'grommet-icons';
import React from 'react';

import { remToPx } from '../../Theme/util';
import { BaseIconProps } from '../types';

export type GrommetIconType =
  | 'accessibility'
  | 'add-circle'
  | 'ascending'
  | 'close'
  | 'configure'
  | 'descending'
  | 'down'
  | 'menu'
  | 'more'
  | 'next'
  | 'pause'
  | 'performance'
  | 'play'
  | 'power-reset'
  | 'previous'
  | 'search'
  | 'share'
  | 'status-good'
  | 'unsorted'
  | 'up'
  | 'update'
  | 'upload';
const grIconMap = {
  accessibility: Accessibility,
  'add-circle': AddCircle,
  ascending: Ascending,
  close: Close,
  configure: Configure,
  descending: Descending,
  down: Down,
  more: MoreVertical,
  menu: Menu,
  next: Next,
  pause: Pause,
  performance: Performance,
  play: Play,
  'power-reset': PowerReset,
  previous: Previous,
  search: Search,
  share: Share,
  'status-good': StatusGood,
  unsorted: Unsorted,
  up: Up,
  update: Update,
  upload: Upload,
};

export type GrommetIconProps = BaseIconProps;

/**
 * Standard grommet icons
 */
export const GrommetIcon: React.FC<GrommetIconProps & JSX.IntrinsicElements['svg']> = ({
  type,
  color = 'currentColor',
  size = '1.5rem',
  ...rest
}) => {
  const MappedIcon = grIconMap[type as GrommetIconType];
  const pxSize = /rem/i.test(size) ? remToPx(size) : size;

  return <MappedIcon {...rest} color={color} size={pxSize} />;
};
