import * as React from 'react';

function SvgIconUser(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path strokeWidth="1.5" d="M3 21c0-4.97 4.03-8 9-8m0 0c4.97 0 9 3.03 9 8m-9-8a5 5 0 100-10 5 5 0 000 10z" />
    </svg>
  );
}

export default SvgIconUser;
