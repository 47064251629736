import * as React from 'react';

function SvgIconCalendar(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path strokeWidth="1.5" d="M4 10.714h16M4 6.43h16V21H4V6.429zm12.8 0V3v3.429zm-9.6 0V3v3.429z" />
    </svg>
  );
}

export default SvgIconCalendar;
