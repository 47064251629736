import * as React from 'react';

function IconTrustedByCertificate(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M0 0H0.754132H12.8202H13.5744V0.754132V3.07909C15.7144 3.43812 17.345 5.29929 17.345 7.54132C17.345 8.88141 16.7625 10.0855 15.8368 10.914V16.5909V18L14.6643 17.2184L12.8202 15.989L10.9762 17.2184L9.80372 18V16.5909V15.0826H0.754132H0V14.3285V0.754132V0ZM9.80372 13.5744V10.914C8.87802 10.0855 8.29545 8.88141 8.29545 7.54132C8.29545 5.29929 9.92611 3.43812 12.0661 3.07909V1.50826H1.50826V13.5744H9.80372ZM11.312 11.8087V15.1818L12.4019 14.4551L12.8202 14.1762L13.2386 14.4551L14.3285 15.1818V11.8087C13.8567 11.9754 13.3491 12.0661 12.8202 12.0661C12.2914 12.0661 11.7838 11.9754 11.312 11.8087ZM12.8202 4.52479C11.1543 4.52479 9.80372 5.87534 9.80372 7.54132C9.80372 9.20727 11.1543 10.5578 12.8202 10.5578C14.4862 10.5578 15.8368 9.20727 15.8368 7.54132C15.8368 5.87534 14.4862 4.52479 12.8202 4.52479Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconTrustedByCertificate;
