import * as React from 'react';

import { IconProps } from '../types';

function SvgIconStatusGoodFilled({
  a11yTitle,
  color = 'currentColor',
  width = '1.5rem',
  height = '1.5rem',
  ...rest
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      {...rest}
      aria-label={a11yTitle}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <circle cx={12} cy={12} r={10} fill={color} stroke={color} strokeWidth={2} />
      <path d="M7 12l4 3 5-7" stroke="#fff" strokeWidth={2} />
    </svg>
  );
}

export default SvgIconStatusGoodFilled;
