import { AxiosError } from 'axios';
import { Text as GrText } from 'grommet';
import { PolymorphicType } from 'grommet/utils';
import React, { useMemo } from 'react';
import styled from 'styled-components';

export enum GenericErrorMessages {
  FallbackError = 'Internal error occured.',
}

export type ErrorType<TError extends Error> = TError | AxiosError<{ message?: string }> | string | null | undefined;

/**
 * Take any type of error and return a string error message.
 */
export type ErrorParser<TError extends Error> = (error: ErrorType<TError>) => string;

export const parseError = <TError extends Error>(
  error: ErrorType<TError>,
  fallback: string = GenericErrorMessages.FallbackError,
): string => {
  if (!error) {
    return fallback;
  }

  // Already an error
  if (typeof error === 'string') return error;
  // Axios response error
  const axiosError = error as AxiosError<{ message?: string }>;
  if (axiosError.response?.data) {
    if (typeof axiosError.response.data === 'string') return axiosError.response.data;
    if (typeof axiosError.response.data?.message === 'string') return axiosError.response.data.message;
  }
  // message error
  if (typeof error?.message === 'string') return error.message;

  return fallback;
};

export interface ErrorMessageProps<TError extends Error> {
  /**
   * Error to parse error message from.
   */
  error: ErrorType<TError>;
  /**
   * Function used to parse the string error message from the passed error structure.
   * The default parser handles string errors, axios response type errors,
   * and objects with `message` property.
   */
  parser?: ErrorParser<TError>;
  /**
   * Default error message will be displayed when parser returns falsey value.
   */
  defaultError?: string;
  /**
   * The DOM tag or react component to use for the element
   */
  as?: PolymorphicType;

  'data-cy'?: string;
}

const StyledError = styled(GrText)(({ theme }) => ({
  color: theme.palette['red-800'],
  fontWeight: 400,
  fontSize: '1rem',
  lineHeight: 1.5,
}));

/**
 * General use component for displaying consistently styled error messages.
 * Pass a custom `parser` function to handle additional error structures.
 */
export function ErrorMessage<TError extends Error>({
  error,
  parser = parseError,
  defaultError = 'An error occured.',
  as = 'span',
  'data-cy': dataCy = 'errorMessage',
  ...rest
}: ErrorMessageProps<TError> & JSX.IntrinsicElements['span']) {
  const message = useMemo(() => parser(error) || defaultError, [error, parser, defaultError]);
  if (!error) {
    return null;
  }

  return (
    <StyledError forwardedAs={as} {...rest} data-cy={dataCy}>
      {message}
    </StyledError>
  );
}
