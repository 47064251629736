import * as React from 'react';

function SvgIconLegal(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9158 15.5725C14.9158 15.1997 15.218 14.8975 15.5908 14.8975H20.7764C21.1492 14.8975 21.4514 15.1997 21.4514 15.5725C21.4514 17.3764 19.989 18.8403 18.1836 18.8403C16.3783 18.8403 14.9158 17.3777 14.9158 15.5725ZM16.3878 16.2475C16.6607 16.9738 17.3615 17.4903 18.1836 17.4903C19.0054 17.4903 19.7061 16.9734 19.9792 16.2475H16.3878Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.1835 8.93677C18.4522 8.93677 18.6953 9.09613 18.8025 9.34252L21.3953 15.3032C21.544 15.645 21.3874 16.0427 21.0455 16.1914C20.7037 16.3401 20.306 16.1835 20.1573 15.8417L18.1835 11.304L16.2097 15.8417C16.061 16.1835 15.6633 16.3401 15.3215 16.1914C14.9796 16.0427 14.823 15.645 14.9717 15.3032L17.5645 9.34252C17.6717 9.09613 17.9148 8.93677 18.1835 8.93677Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.5498 15.6591C2.5498 15.2863 2.85201 14.9841 3.2248 14.9841H8.41041C8.7832 14.9841 9.08541 15.2863 9.08541 15.6591C9.08541 17.4644 7.62287 18.9269 5.8176 18.9269C4.01215 18.9269 2.5498 17.4631 2.5498 15.6591ZM4.02199 16.3341C4.29506 17.0601 4.99581 17.5769 5.8176 17.5769C6.63969 17.5769 7.34046 17.0605 7.61338 16.3341H4.02199Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.81778 9.02344C6.08647 9.02344 6.32958 9.1828 6.43675 9.42919L9.02955 15.3898C9.17825 15.7317 9.02167 16.1294 8.67982 16.2781C8.33797 16.4268 7.9403 16.2702 7.7916 15.9283L5.81778 11.3907L3.84395 15.9283C3.69525 16.2702 3.29758 16.4268 2.95573 16.2781C2.61388 16.1294 2.4573 15.7317 2.606 15.3898L5.1988 9.42919C5.30598 9.1828 5.54908 9.02344 5.81778 9.02344Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 8.3249C3 7.95211 3.30221 7.6499 3.675 7.6499H20.325C20.6978 7.6499 21 7.95211 21 8.3249C21 8.69769 20.6978 8.9999 20.325 8.9999H3.675C3.30221 8.9999 3 8.69769 3 8.3249Z"
        fill="currentColor"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.75 3L12.75 21L11.4 21L11.4 3L12.75 3Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 21.1499L9 21.1499L9 19.7999L15 19.7999L15 21.1499Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconLegal;
