import * as React from 'react';

function SvgIconSolutionAutomatedFixes(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M16 7.176A8.824 8.824 0 0 0 7.176 16 .588.588 0 1 1 6 16c0-5.523 4.477-10 10-10a9.986 9.986 0 0 1 8 4 .588.588 0 0 1-.94.706A8.81 8.81 0 0 0 16 7.176Zm9.412 8.236c.325 0 .588.263.588.588 0 5.523-4.477 10-10 10a9.986 9.986 0 0 1-8-4 .588.588 0 1 1 .94-.706A8.823 8.823 0 0 0 24.823 16c0-.325.264-.588.589-.588Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M8.656 21.089a.588.588 0 0 1 .372.744l-.94 2.824a.588.588 0 0 1-1.117-.372l.941-2.824a.588.588 0 0 1 .744-.372ZM24.657 6.971a.588.588 0 0 1 .372.744l-.942 2.824a.588.588 0 0 1-1.116-.372l.941-2.824a.588.588 0 0 1 .745-.372ZM13.665 11.608c0-.964.818-1.687 1.753-1.687h1.164c.935 0 1.753.723 1.753 1.687 0 .247.265.405.483.286l.281.517-.281-.517.062-.033.28.516-.28-.516c.818-.446 1.88-.194 2.373.611l.583.951c.506.827.19 1.868-.647 2.324-.2.109-.2.397 0 .506.837.456 1.153 1.497.647 2.324l-.583.95c-.493.806-1.555 1.058-2.373.612l-.062-.034a.327.327 0 0 0-.483.287c0 .963-.818 1.686-1.753 1.686h-1.164c-.935 0-1.753-.723-1.753-1.686a.327.327 0 0 0-.483-.287l-.28-.516.28.516-.062.034c-.818.446-1.88.194-2.373-.611l-.582-.951.497-.305-.498.305c-.506-.828-.19-1.869.647-2.324.2-.11.2-.397 0-.506-.837-.456-1.153-1.497-.646-2.324l.582-.95c.493-.806 1.555-1.058 2.373-.612l-.28.516.28-.516.062.033-.28.517.28-.517a.327.327 0 0 0 .483-.286Zm1.753-.51c-.352 0-.577.26-.577.51 0 1.14-1.22 1.865-2.221 1.32l.27-.495-.27.495-.062-.034c-.295-.16-.658-.053-.808.192l-.582.951c-.137.224-.071.526.206.676a1.465 1.465 0 0 1 0 2.573c-.277.15-.343.453-.206.676l.582.951c.15.245.513.353.808.193l.281.516-.281-.516.062-.034a1.503 1.503 0 0 1 2.221 1.32c0 .25.225.51.577.51h1.164c.352 0 .577-.26.577-.51 0-1.14 1.22-1.865 2.221-1.32l.062.034c.295.16.658.052.808-.193l.582-.95c.137-.224.072-.526-.205-.677a1.464 1.464 0 0 1 0-2.573c.277-.15.342-.452.205-.676l-.582-.95c-.15-.246-.513-.354-.808-.193l-.277-.51.277.51-.062.034a1.503 1.503 0 0 1-2.221-1.32c0-.25-.225-.51-.577-.51h-1.164Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M16 15.02a.98.98 0 1 0 0 1.96.98.98 0 0 0 0-1.96Zm-2.157.98a2.157 2.157 0 1 1 4.314 0 2.157 2.157 0 0 1-4.314 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconSolutionAutomatedFixes;
