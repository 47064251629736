import { base, ThemeType as GrommetTheme } from 'grommet';
import { deepMerge } from 'grommet/utils';

import { breakpoints } from './breakpoints';
import { palette } from './palette';

// Override grommet theme values
export const grommetOverrides: GrommetTheme = {
  global: {
    breakpoints: {
      small: {
        value: breakpoints.small,
      },
      medium: {
        value: breakpoints.medium,
      },
      dashboard: {
        value: 960,
      },
    },
    colors: {
      brand: palette['purple-600'],
      brandText: palette.white,
      brandHover: palette['purple-400'],
      brandHoverText: palette.white,
      brandActive: palette['purple-900'],
      brandActiveText: palette.white,
      secondary: 'transparent',
      secondaryText: palette['purple-600'],
      secondaryHover: palette['purple-50'],
      secondaryHoverText: palette['purple-600'],
      secondaryActive: palette['purple-600'],
      secondaryActiveText: palette.white,

      successBg: palette['green-200'],
      successFg: palette['green-800'],
      infoBg: palette['purple-100'],
      infoFg: palette['purple-900'],
      defaultBg: palette['gray-100'],
      defaultFg: palette['gray-600'],
      warningBg: palette['yellow-200'],
      warningFg: palette['yellow-800'],
      errorBg: palette['red-200'],
      errorFg: palette['red-800'],

      alert: palette['red-800'],
    },
    control: {
      border: {
        width: '1px',
        radius: '4px',
      },
    },
    font: {
      family: 'Inter, Helvetica, Arial, sans-serif',
      size: '1rem',
    },
  },
  text: {
    small: { size: '0.75rem', height: '1rem' },
    large: { size: '1.125rem', height: '1.25rem' },
  },
  checkBox: {
    check: {
      extend: () => `
        background-color: ${palette.white};
        height: 18px;
        width: 18px;
        `,
    },
  },
  anchor: {
    color: palette['purple-600'],
    hover: {
      extend: `
        color: ${palette['purple-400']};
      `,
    },
    extend: `
      svg {
        stroke: ${palette['purple-600']};
      }
      &:active {
        color: ${palette['purple-900']} !important;
        svg {
          stroke: ${palette['purple-900']} !important;
        }
      }
    `,
  },
  avatar: {
    size: {
      small: '34px',
    },
  },
  formField: {
    border: false,
  },
  select: {
    background: palette.white,
    control: {
      extend: 'overflow: hidden',
    },
  },
};

export const grommetTheme = deepMerge(base, grommetOverrides);
