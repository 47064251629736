import * as React from 'react';

function IconSectionResolution(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width="32"
      height="32"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="28" cy="28" r="28" fill="#E7DEFC" />
      <g clipPath="url(#clip0_414_766)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.8569 17.1561C34.4419 19.1364 31.3527 20.3248 27.986 20.3248C24.6258 20.3248 21.5421 19.141 19.1292 17.1677C21.5442 15.1874 24.6333 13.999 28.0001 13.999C31.3603 13.999 34.444 15.1828 36.8569 17.1561Z"
          fill="#7119E1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M36.9792 38.7417C34.5486 40.7756 31.4173 41.9999 28.0001 41.9999C24.5383 41.9999 21.37 40.7434 18.9263 38.6616C21.3568 36.6277 24.4881 35.4034 27.9053 35.4034C31.3671 35.4034 34.5355 36.6599 36.9792 38.7417Z"
          fill="#7119E1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M38.7552 19.0372C40.7811 21.4655 42 24.5905 42 28.0002C42 31.4506 40.7518 34.6095 38.6823 37.0499C36.6564 34.6216 35.4375 31.4966 35.4375 28.0869C35.4375 24.6365 36.6857 21.4777 38.7552 19.0372Z"
          fill="#7119E1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.1993 19.0907C19.1388 21.4932 20.3002 24.5502 20.3002 27.8783C20.3002 31.2624 19.0995 34.3661 17.1009 36.7865C15.1613 34.384 14 31.3271 14 27.9989C14 24.6148 15.2007 21.5112 17.1993 19.0907Z"
          fill="#7119E1"
        />
      </g>
      <defs>
        <clipPath id="clip0_414_766">
          <rect width="28" height="28" fill="white" transform="translate(14 14)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconSectionResolution;
