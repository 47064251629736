import * as React from 'react';

function SvgIconSolutionAutomatedTesting(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M16 11.883a4.118 4.118 0 1 0 0 8.235 4.118 4.118 0 0 0 0-8.235ZM10.706 16a5.294 5.294 0 1 1 10.588 0 5.294 5.294 0 0 1-10.588 0Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M16 15.02a.98.98 0 1 0 0 1.96.98.98 0 0 0 0-1.96Zm-2.157.98a2.157 2.157 0 1 1 4.314 0 2.157 2.157 0 0 1-4.314 0ZM16 8.824c.325 0 .588.263.588.588v1.318a.588.588 0 0 1-1.176 0V9.412c0-.325.263-.588.588-.588ZM16 20.683c.325 0 .588.263.588.588v1.317a.588.588 0 0 1-1.176 0v-1.317c0-.325.263-.588.588-.588ZM23.177 16a.588.588 0 0 1-.589.589h-1.317a.588.588 0 0 1 0-1.177h1.317c.325 0 .588.263.588.588ZM11.317 16a.588.588 0 0 1-.588.589H9.412a.588.588 0 0 1 0-1.177h1.317c.325 0 .588.263.588.588Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M16 7.176A8.824 8.824 0 0 0 7.176 16 .588.588 0 1 1 6 16c0-5.523 4.477-10 10-10a9.986 9.986 0 0 1 8 4 .588.588 0 0 1-.94.706A8.81 8.81 0 0 0 16 7.176Zm9.412 8.236c.325 0 .588.263.588.588 0 5.523-4.477 10-10 10a9.986 9.986 0 0 1-8-4 .588.588 0 1 1 .94-.706A8.823 8.823 0 0 0 24.823 16c0-.325.264-.588.589-.588Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M8.656 21.089a.588.588 0 0 1 .372.744l-.94 2.824a.588.588 0 0 1-1.117-.372l.941-2.824a.588.588 0 0 1 .744-.372ZM24.657 6.971a.588.588 0 0 1 .372.744l-.942 2.824a.588.588 0 0 1-1.116-.372l.941-2.824a.588.588 0 0 1 .745-.372Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconSolutionAutomatedTesting;
