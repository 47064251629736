import * as React from 'react';

function SvgIconDownload(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path strokeWidth="1.5" d="M3 15.143V20h18v-4.857M12 3v13.762m0 0l-5.727-5.667M12 16.762l5.727-5.667" />
    </svg>
  );
}

export default SvgIconDownload;
