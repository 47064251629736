import * as React from 'react';

function IconAbilityVisual(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width="32"
      height="32"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="28" cy="28" r="28" fill="#E7DEFC" />
      <circle cx="28.0156" cy="28.0156" r="5.19531" stroke="#7119E1" strokeWidth="1.64062" />
      <path
        d="M28.2748 20.0237C20.9429 19.5862 14.8669 25.3102 13 28.2268C15.0366 31.4844 20.3326 36.4062 27.7656 36.4062C34.8939 36.4062 40.4946 31.4844 42.5313 28.2268C40.8341 25.6748 35.6067 20.4612 28.2748 20.0237Z"
        stroke="#7119E1"
        strokeWidth="1.64062"
      />
    </svg>
  );
}

export default IconAbilityVisual;
