import * as React from 'react';

function SvgIconCode(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        strokeWidth="1.333333"
        d="M9.417 21.333L14.917 3m1.833 13.75l4.583-4.583-4.583-4.584M7.583 16.75L3 12.167l4.583-4.584"
      />
    </svg>
  );
}

export default SvgIconCode;
