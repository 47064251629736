import * as React from 'react';

function SvgIconSolutionReportingGuidance(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
      <path
        fill="#000"
        d="M10.706 10.445a2.941 2.941 0 0 1 2.941-2.942h1.569V8.68h-1.569c-.975 0-1.765.79-1.765 1.765v5.445h-1.176v-5.445ZM23.059 7.503a2.941 2.941 0 0 1 2.94 2.942v10.891a2.941 2.941 0 0 1-2.94 2.942h-2.353V23.1h2.353c.974 0 1.764-.79 1.764-1.765V10.445c0-.975-.79-1.765-1.764-1.765H21.49V7.503h1.569Z"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M14.739 7.219c0-.758.614-1.373 1.373-1.373h4.481c.758 0 1.373.615 1.373 1.373v2.33c0 .759-.615 1.373-1.373 1.373h-4.481a1.373 1.373 0 0 1-1.373-1.372V7.219Zm1.373-.196a.196.196 0 0 0-.196.196v2.33c0 .109.087.197.196.197h4.481a.196.196 0 0 0 .196-.196V7.219a.196.196 0 0 0-.196-.196h-4.481ZM6 17.415c0-1.191.966-2.157 2.157-2.157h10.98c1.191 0 2.157.966 2.157 2.157v6.274a2.157 2.157 0 0 1-2.157 2.157H8.157A2.157 2.157 0 0 1 6 23.689v-6.274Zm2.157-.98a.98.98 0 0 0-.98.98v6.274c0 .542.438.98.98.98h10.98a.98.98 0 0 0 .98-.98v-6.274a.98.98 0 0 0-.98-.98H8.157Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M11.66 20.654a.588.588 0 0 1 .095.826l-1.125 1.421a.955.955 0 0 1-1.39.129l-.688-.608a.588.588 0 0 1 .779-.881l.515.455.987-1.246a.588.588 0 0 1 .826-.096ZM12.641 22.144c0-.325.264-.588.589-.588h5.098a.588.588 0 1 1 0 1.176H13.23a.588.588 0 0 1-.589-.588ZM11.66 17.517a.588.588 0 0 1 .095.826l-1.125 1.42a.955.955 0 0 1-1.39.13l-.688-.608a.588.588 0 0 1 .779-.882l.515.456.987-1.246a.588.588 0 0 1 .826-.096ZM12.641 19.007c0-.325.264-.588.589-.588h5.098a.588.588 0 1 1 0 1.176H13.23a.588.588 0 0 1-.589-.588Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconSolutionReportingGuidance;
