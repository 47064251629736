import * as React from 'react';

function SvgIconSolutionOnlineTraining(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
      <path
        fill="#000"
        d="M23.787 10.221c1.222 0 2.213 1.026 2.213 2.292v11.012c0 1.842-1.99 2.931-3.46 1.894l-1.807-1.277a.977.977 0 0 0-1.133 0l-1.807 1.277c-1.47 1.037-3.46-.052-3.46-1.894v-1.429h1.207v1.43c0 .836.905 1.332 1.573.86l1.807-1.277a2.15 2.15 0 0 1 2.493 0l1.808 1.277c.667.472 1.572-.024 1.572-.86V12.512c0-.575-.45-1.042-1.006-1.042h-4.62v-1.25h4.62Z"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M6 8.787a2.941 2.941 0 0 1 2.941-2.94h7.843a2.941 2.941 0 0 1 2.942 2.94v10.98a2.941 2.941 0 0 1-2.942 2.942H8.941A2.941 2.941 0 0 1 6 19.768V8.788Zm1.176 10.98c0 .975.79 1.765 1.765 1.765h7.843c.975 0 1.765-.79 1.765-1.764v-1.765H8.941c-.974 0-1.765.79-1.765 1.765Zm11.373-2.94H8.941c-.662 0-1.273.218-1.765.588V8.787c0-.974.79-1.764 1.765-1.764h7.843c.975 0 1.765.79 1.765 1.764v8.04Zm-7.843-5.986a1.373 1.373 0 0 1 1.986-1.228l2.168 1.084c1.012.506 1.012 1.95 0 2.455l-2.168 1.084a1.373 1.373 0 0 1-1.986-1.228v-2.167Zm1.46-.176a.196.196 0 0 0-.284.176v2.168c0 .145.154.24.284.175l2.168-1.084a.196.196 0 0 0 0-.35l-2.168-1.085Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconSolutionOnlineTraining;
