import * as React from 'react';

import { IconProps } from '../types';

function SvgIconVideo({ color = 'currentColor', ...rest }: IconProps) {
  return (
    <svg
      aria-hidden
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect x="8.48193" y="5.625" width="9.89292" height="9.89292" stroke={color} strokeWidth="1.25" />
      <path d="M5 7.88965V19.0326H14.9048" stroke={color} strokeWidth="1.25" />
      <path d="M16.1001 10.6856L11.9676 7.97039V13.4004L16.1001 10.6856Z" fill={color} />
    </svg>
  );
}

export default SvgIconVideo;
