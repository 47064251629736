import * as React from 'react';

function IconLegalDocuments(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        // eslint-disable-next-line max-len
        d="M8.5 7.75H4M5.5 10.75H4M10 4.75H4M13 7V4.6C13 3.33988 13 2.70982 12.7548 2.22852C12.539 1.80516 12.1948 1.46095 11.7715 1.24524C11.2902 1 10.6601 1 9.4 1H4.6C3.33988 1 2.70982 1 2.22852 1.24524C1.80516 1.46095 1.46095 1.80516 1.24524 2.22852C1 2.70982 1 3.33988 1 4.6V12.4C1 13.6601 1 14.2902 1.24524 14.7715C1.46095 15.1948 1.80516 15.539 2.22852 15.7548C2.70982 16 3.33988 16 4.6 16H7.375M11.5 15.25C11.5 15.25 13.75 14.1776 13.75 12.5689V10.6922L12.1093 10.1059C11.7151 9.9647 11.284 9.9647 10.8898 10.1059L9.25 10.6922V12.5689C9.25 14.1776 11.5 15.25 11.5 15.25Z"
        stroke="currentColor"
        strokeWidth="1.35"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default IconLegalDocuments;
