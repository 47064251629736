import * as React from 'react';

import { IconProps } from '../types';

function SvgIconTooltip({ a11yTitle, color = 'currentColor', ...rest }: IconProps) {
  return (
    <svg
      width="24"
      height="24"
      aria-label={a11yTitle}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      {...rest}
    >
      <path
        fill={color}
        d="M11.31 13.701v-.605c0-.161.007-.297.022-.407a1.17 1.17 0 01.099-.308c.051-.095.114-.183.187-.264.08-.088.176-.19.286-.308l.847-.858c.183-.183.275-.422.275-.715a.899.899 0 00-.286-.693.953.953 0 00-.704-.275.991.991 0 00-.759.319 1.232 1.232 0 00-.341.759l-1.408-.11a2.79 2.79 0 01.275-.935 2.25 2.25 0 01.539-.704c.227-.19.488-.334.781-.429.293-.103.616-.154.968-.154.33 0 .634.048.913.143.286.095.532.235.737.418.213.176.378.4.495.671.117.264.176.568.176.913 0 .242-.033.455-.099.638a2.075 2.075 0 01-.264.506c-.11.154-.238.3-.385.44-.14.14-.29.286-.451.44-.103.095-.19.18-.264.253-.073.073-.136.15-.187.231a.86.86 0 00-.099.264 1.594 1.594 0 00-.033.352v.418h-1.32zm-.22 1.529c0-.235.084-.436.253-.605a.875.875 0 01.627-.253c.235 0 .44.08.616.242.176.161.264.36.264.594a.809.809 0 01-.264.605.838.838 0 01-.616.253.91.91 0 01-.814-.506.829.829 0 01-.066-.33z"
      />
      <path
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 19a7 7 0 100-14 7 7 0 000 14z"
      />
    </svg>
  );
}

export default SvgIconTooltip;
