import * as React from 'react';

function SvgIconTrashcan(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <path
        strokeWidth="1.5"
        d="M9.667 9.667V18M5.5 6.333h13.333v15H5.5v-15zm-2.5 0h18.333H3zM9.667 3h5v3.333h-5V3zm0 0h5v3.333h-5V3zm5 6.667V18 9.667z"
      />
    </svg>
  );
}

export default SvgIconTrashcan;
