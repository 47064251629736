import * as React from 'react';

function SvgIconSolutionVisualToolkit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
      <path
        fill="#000"
        d="M6 8.787a2.941 2.941 0 0 1 2.941-2.94H23.06a2.941 2.941 0 0 1 2.94 2.94v7.844a2.942 2.942 0 0 1-2.156 2.835l-.784-1.07c.974 0 1.764-.79 1.764-1.765V8.787c0-.974-.79-1.764-1.764-1.764H8.94c-.974 0-1.765.79-1.765 1.764v7.844c0 .974.79 1.764 1.765 1.764l-.784 1.07A2.942 2.942 0 0 1 6 16.632V8.787Z"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M6.588 11.336c0-.325.264-.588.589-.588h17.647a.588.588 0 0 1 0 1.177H7.177a.588.588 0 0 1-.589-.589Z"
        clipRule="evenodd"
      />
      <circle cx={9.725} cy={8.787} r={0.784} fill="#000" />
      <circle cx={14.431} cy={8.787} r={0.784} fill="#000" />
      <circle cx={12.079} cy={8.787} r={0.784} fill="#000" />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M16 14.866c-2.73 0-5.213 2.022-6.735 3.623a1.831 1.831 0 0 0 0 2.558c1.522 1.6 4.005 3.623 6.735 3.623 2.73 0 5.213-2.023 6.735-3.623a1.831 1.831 0 0 0 0-2.558c-1.522-1.6-4.006-3.623-6.735-3.623Zm-7.587 2.813c1.546-1.627 4.331-3.99 7.587-3.99 3.256 0 6.04 2.363 7.587 3.99a3.007 3.007 0 0 1 0 4.178c-1.546 1.627-4.331 3.99-7.587 3.99-3.256 0-6.041-2.363-7.587-3.99a3.007 3.007 0 0 1 0-4.178Zm7.587.324a1.765 1.765 0 1 0 0 3.53 1.765 1.765 0 0 0 0-3.53Zm-2.941 1.765a2.941 2.941 0 1 1 5.882 0 2.941 2.941 0 0 1-5.882 0Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconSolutionVisualToolkit;
