import { Heading as GrHeading, HeadingProps as GrHeadingProps } from 'grommet';
import { TextAlignType } from 'grommet/utils';
import React, { ReactNode, useMemo } from 'react';
import styled, { CSSObject } from 'styled-components';

export type HeadingVariant = 'h1-large' | 'h2-large' | 'h3-large' | 'h4-large';

export interface HeadingProps
  extends Omit<GrHeadingProps, 'alignSelf' | 'color' | 'gridArea' | 'fill' | 'responsive' | 'size'> {
  /**
   * Custom label to be used by screen readers.
   * When provided, an aria-label will be added to the element.
   */
  a11yTitle?: string;
  /**
   * Heading Content
   */
  children?: ReactNode;
  /**
   * Heading Level. Make sure to follow wcag guidelines on page structure
   * for heading level usage.
   * NOTE: This library only supplies style variants for h1-h3 small/large and h4 small.
   * Using additional heading levels will default to h4-large style variant.
   */
  level?: '1' | '2' | '3' | '4' | '5' | '6' | 1 | 2 | 3 | 4 | 5 | 6;
  /**
   * How to align the text inside the component.
   */
  textAlign?: TextAlignType;
  /**
   * Restrict the text to a single line and truncate with ellipsis if it is too long to all fit.
   * For truncate to be applied, Text needs to be contained within a layout component.
   */
  truncate?: boolean;
  /**
   * Using the style variant prop will override the applied style
   * derived from `level` and `size`
   */
  variant?: HeadingVariant;
  responsive?: boolean;
  id?: string;
}

const variantStyle: Record<HeadingVariant, CSSObject> = {
  'h1-large': {
    fontWeight: 700,
    fontSize: '2rem',
    lineHeight: 1.5,
  },
  'h2-large': {
    fontWeight: 700,
    fontSize: '1.5rem',
    lineHeight: 1.667,
  },
  'h3-large': {
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: 1.5,
    margin: 0,
  },
  'h4-large': {
    fontWeight: 600,
    fontSize: '.875rem',
    lineHeight: 1.5,
  },
};

const VariantComponents = {
  'h1-large': styled(GrHeading)(variantStyle['h1-large']),
  'h2-large': styled(GrHeading)(variantStyle['h2-large']),
  'h3-large': styled(GrHeading)(variantStyle['h3-large']),
  'h4-large': styled(GrHeading)(variantStyle['h4-large']),
};

/**
 * General use text component with predefined variant styles.
 */
export const Heading: React.FC<HeadingProps> = ({ level = 2, variant, ...rest }: HeadingProps) => {
  const HeadingVariantComponent = useMemo(() => {
    if (variant) return VariantComponents[variant];
    const variantString = `h${level}-large` as HeadingVariant;

    // Default to h4-large for undefined variants
    return VariantComponents[variantString] || VariantComponents['h4-large'];
  }, [level, variant]);

  return <HeadingVariantComponent fill={rest.as ? undefined : true} level={level} margin="none" {...rest} />;
};
