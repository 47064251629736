import { Box, BoxTypes, Button as GrButton } from 'grommet';
import React, { ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

import { Icon } from '../Icon/Icon';

export type BannerVariant = 'success' | 'info' | 'default' | 'warning' | 'error';

export interface BannerProps extends BoxTypes {
  /**
   * The banner content
   */
  children?: ReactNode;
  /**
   * Callback close handler. Controls whether or not to display close button
   */
  onClose?: () => void | Promise<void>;
  /**
   *  Banner style variant
   */
  variant?: BannerVariant;
  /**
   * Override the close icon
   */
  closeIcon?: React.ReactElement;
}

const StyledBannerWrapper = styled(Box)<BannerProps>(({ theme: { palette }, variant = 'default' }) => {
  const baseBannerStyle: CSSObject = {
    borderRadius: '8px',
    textAlign: 'center',
    paddingLeft: '1rem',
    paddingRight: '1rem',

    // eslint-disable-next-line @typescript-eslint/naming-convention
    '& .banner-content': {
      textAlign: 'center',
      paddingTop: '1rem',
      paddingBottom: '1rem',
    },
  };

  switch (variant) {
    case 'success': {
      return {
        ...baseBannerStyle,
        backgroundColor: palette['green-200'],
        color: palette['green-800'],
      };
    }
    case 'info': {
      return {
        ...baseBannerStyle,
        backgroundColor: palette['purple-100'],
        color: palette['purple-900'],
      };
    }
    case 'warning': {
      return {
        ...baseBannerStyle,
        backgroundColor: palette['purple-800'],
        color: palette.white,
      };
    }
    case 'error': {
      return {
        ...baseBannerStyle,
        backgroundColor: palette['red-200'],
        color: palette['red-800'],
      };
    }
    case 'default':
    default: {
      return {
        ...baseBannerStyle,
        backgroundColor: palette['gray-100'],
        color: palette['gray-600'],
      };
    }
  }
});

/**
 * Banner notification component. Extends `Box` props.
 */
export const Banner: React.FC<BannerProps> = ({
  children,
  onClose,
  closeIcon = <Icon type="close" />,
  ...rest
}: BannerProps) => (
  <StyledBannerWrapper {...rest} direction="row" justify={onClose ? 'between' : 'center'} align="center">
    <Box className="banner-content" flex>
      {children}
    </Box>
    {onClose && (
      <Box>
        <GrButton color="inherit" icon={closeIcon} onClick={onClose} />
      </Box>
    )}
  </StyledBannerWrapper>
);
