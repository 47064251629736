import * as React from 'react';

function IconInfoCircle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_428_1320)">
        <path
          d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85652 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85652 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85652 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85652 15 8 15ZM8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16Z"
          fill="black"
        />
        <path
          d="M8.93001 6.588L6.64001 6.875L6.55801 7.255L7.00801 7.338C7.30201 7.408 7.36001 7.514 7.29601 7.807L6.55801 11.275C6.36401 12.172 6.66301 12.594 7.36601 12.594C7.91101 12.594 8.54401 12.342 8.83101 11.996L8.91901 11.58C8.71901 11.756 8.42701 11.826 8.23301 11.826C7.95801 11.826 7.85801 11.633 7.92901 11.293L8.93001 6.588ZM9.00001 4.5C9.00001 4.76522 8.89466 5.01957 8.70712 5.20711C8.51958 5.39464 8.26523 5.5 8.00001 5.5C7.7348 5.5 7.48044 5.39464 7.29291 5.20711C7.10537 5.01957 7.00001 4.76522 7.00001 4.5C7.00001 4.23478 7.10537 3.98043 7.29291 3.79289C7.48044 3.60536 7.7348 3.5 8.00001 3.5C8.26523 3.5 8.51958 3.60536 8.70712 3.79289C8.89466 3.98043 9.00001 4.23478 9.00001 4.5Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_428_1320">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default IconInfoCircle;
