import * as React from 'react';

function SvgIconSolutionManualAudit(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={32} height={32} fill="none" {...props}>
      <path
        fill="#000"
        fillRule="evenodd"
        d="M15.216 8.902a6.314 6.314 0 1 0 1.524 12.442l.283 1.142a7.49 7.49 0 1 1 5.447-5.4l-1.14-.293a6.314 6.314 0 0 0-6.115-7.89Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M15.216 13.216a2 2 0 1 0 .303 3.977l.177 1.163a3.176 3.176 0 1 1 2.67-2.728l-1.167-.152a2 2 0 0 0-1.983-2.261ZM15.216 6c.325 0 .588.263.588.588v1.726a.588.588 0 1 1-1.177 0V6.588c0-.325.264-.588.589-.588ZM15.216 21.53c.325 0 .588.263.588.587v1.726a.588.588 0 1 1-1.177 0v-1.726c0-.324.264-.588.589-.588ZM24.431 15.216a.588.588 0 0 1-.588.588h-1.725a.588.588 0 1 1 0-1.177h1.725c.325 0 .588.264.588.589ZM8.902 15.216a.588.588 0 0 1-.588.588H6.588a.588.588 0 0 1 0-1.177h1.726c.324 0 .588.264.588.589Z"
        clipRule="evenodd"
      />
      <path
        fill="#000"
        fillRule="evenodd"
        d="M17.253 14.732c-1.558-.52-3.04.963-2.52 2.521l2.46 7.384c.606 1.817 3.176 1.817 3.782 0l.786-2.36a.816.816 0 0 1 .517-.515l2.36-.787c1.816-.606 1.816-3.176 0-3.781l-7.385-2.462Zm-1.405 2.15a.816.816 0 0 1 1.033-1.034l7.384 2.462c.745.248.745 1.3 0 1.549l-2.36.786a1.993 1.993 0 0 0-1.26 1.26l-.786 2.36c-.248.745-1.301.745-1.55 0l-2.46-7.384-.486.162.485-.162Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default SvgIconSolutionManualAudit;
