import * as React from 'react';

function SvgIconAccessibilityStatement(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      aria-hidden
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 4C4.34315 4 3 5.34315 3 7V7.75H3.75H7.38524L6.00754 17.394L6 17.4467V17.5C6 19.1568 7.34315 20.5 9 20.5H18C19.6568 20.5 21 19.1568 21 17.5V16.75H20.25H16.6148L17.9925 7.10607L18 7.0533V7C18 5.34315 16.6568 4 15 4H6ZM6 5.5C5.44479 5.5 4.96003 5.80165 4.70067 6.25H7.29933C7.03997 5.80165 6.55521 5.5 6 5.5ZM8.59865 5.5H15C15.8123 5.5 16.4739 6.14577 16.4993 6.95198L15.0995 16.75H11.25H10.5V17.5C10.5 18.3285 9.82843 19 9 19C8.18764 19 7.52611 18.3542 7.50076 17.548L8.99246 7.10607L9 7.0533V7C9 6.45357 8.85391 5.94126 8.59865 5.5ZM18 19H11.5987C11.7329 18.7679 11.837 18.5161 11.9055 18.25H19.2993C19.04 18.6984 18.5552 19 18 19Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgIconAccessibilityStatement;
